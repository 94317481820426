import React from "react";
import { extractError } from "../fetchUtils";

interface IUseFetchFileProps {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error?: string;
    refetch: ( path: string ) => void;
}

/** Returns a hook to fetch json files and return their content */
export function useFetchFile( filepath: string ): IUseFetchFileProps {
  const [ isLoading, setIsLoading ] = React.useState<boolean>( true );
  const [ isError, setIsError ] = React.useState<boolean>( false );
  const [ data, setData ] = React.useState<any>( null );
  const [ error, setError ] = React.useState<string>();
  const getData = ( path: string ) => {
    setIsLoading( true );
    setIsError( false );
    fetch( path.startsWith( "/" ) ? path : `/${path}`, {
      headers : {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(( response ) => {
      return response.json();
    }).then(( parsedData ) => {
      setData( parsedData );
    }).catch(( err ) => {
      setIsError( true );
      setError( extractError( err ));
    }).finally( function() {
      setIsLoading( false );
    });
  };
  React.useEffect(() => {
    getData( filepath );
  }, [filepath]);
  return {
    isLoading, isError, data, error, refetch: getData
  };
}