import { IPermanentPaths, IPath, PathKeys, Paths, PermanentPathObject, RouteObject } from "../types/Routes.types";
import permanentPaths from "../constants/permanentPaths.json";

export const getAllRoutes = ( rawPermanentPaths: PermanentPathObject ): PermanentPathObject => {
  try {
    const paths: PermanentPathObject = {};
    Object.entries( rawPermanentPaths ).forEach(([ identifier = "root" as string, obj ]) => {
      const { parent, path, externalLink = false }: Partial<RouteObject> = obj;
      if ( identifier === "root" ) {
        paths[identifier] = { ...obj, relativePath: "/" };
      } else {
        paths[identifier] = {
          ...obj,
          path: parent === "root" ? ( externalLink ? path : `/${path}` ) : `${paths?.[parent]?.path}${path}`,
          relativePath: parent === "root" ? ( externalLink ? path : `/${path}` ) : `${path?.replace( /^\//, "" )}`
        };
      }
    });
    return paths;
  } catch ( _error ) {
    return {};
  }
};

// eslint-disable-next-line
export const R = <T, K extends keyof IPath>(routeIdentifier: string, key?: K): string => {
  const { routes }: IPermanentPaths = permanentPaths;
  const routeObject = routes?.[routeIdentifier as PathKeys] ?? {};
  if ( !key ) {
    return routeObject?.path;
  } else {
    return routeObject?.[key] ?? "/";
  }
};

const computePath = ( routeId: string, currentPath?: string ): string => {
  const { routes }: IPermanentPaths = permanentPaths;
  if ( Object.keys( routes )?.includes( routeId )) {
    const finalPath = routes[ routeId as PathKeys ].path + ( currentPath ?? "" );
    if ( routes[routeId as PathKeys].parent === "root" ){
      return finalPath;
    } else {
      return computePath( routes[routeId as PathKeys].parent, finalPath );
    }
  }
  return "/404";
};

export const Routes = (): Paths | Record<string, never> => {
  const { routes }: IPermanentPaths = permanentPaths;
  return Object.keys( routes ).reduce(( a, c ) => ({ ...a, [c]: computePath( c ) }), {});
};

export const TrimRawPath = ( rawPath: IPath ) : string => rawPath.path.slice( 1 );
