// Libraries
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import config from "./config.json";

// components
import "./components/common/Icon/IconLibrary";
import { IPrivateRoutes } from "./types/Routes.types";
import { useAppSelector } from "./store/hooks";
import { RootState } from "./store";
import { useRoutes } from "./utils/hooks/useRoutes";
import ReportsLayout from "./components/reports/reportsLayout";
import { useTranslation } from "react-i18next";
import "./assets/css/App.scss";
import Header from "./components/layout/Header";
import Logout from "./components/account/userProfile/logout";
import About from "./components/about";
import Footer from "./components/layout/Footer"; // Import Footer component
import { main } from "@popperjs/core";

const P404 = React.lazy(() => import("./components/layout/P404"));
const Home = React.lazy(() => import("./components/home"));
const RequestSubscription = React.lazy(() => import("./components/home/requestSubscription"));
const ChangePassword = React.lazy(() => import("./components/home/changePassword"));
const ViewUser = React.lazy(() => import("./components/usermanagment/UserList"));
const SubscriptionApprove = React.lazy(() => import("./components/home/viewUser/UserList"));
const ViewUserProfile = React.lazy(() => import("./components/home/viewUser/viewUserProfile"));
// const Register = React.lazy(() => import("./components/auth/register"));
const Register = React.lazy(() => import("./components/auth/register_withsubscription"));
const RegisterAdmin = React.lazy(() => import("./components/auth/registerAdmin"));
const ForgotPassword = React.lazy(() => import("./components/auth/forgotPassword"));
const VerifyOtp = React.lazy(() => import("./components/auth/verifyOTP"));
const ManageNews = React.lazy(() => import("./components/media/news/form"));
const UserAgrement = React.lazy(() => import("./components/auth/userAgrement"));
const PaymentReport = React.lazy(() => import("./components/paymentstatus/paymentreport"));
const AqariviewerReport = React.lazy(() => import("./components/reports/aqariViewerReport"));
const LoginReport = React.lazy(() => import("./components/reports/loginReport"));
const AqariViewer = React.lazy(() => import("./components/GIS/aqariViewer"));
const Media = React.lazy(() => import("./components/media"));
const Contact = React.lazy(() => import("./components/about/contact-us"));
const FAQs = React.lazy(() => import("./components/about/faq"));
const ResetPassword = React.lazy(() => import("./components/home/resetpassword"));
const Partners = React.lazy(() => import("./components/about/partners"));
const AboutAqari = React.lazy(() => import("./components/about/aqari"));
const Auth = React.lazy(() => import("./components/auth"));
const GIS = React.lazy(() => import("./components/GIS"));
const Aqari = React.lazy(() => import("./components/aqari"));
const Account = React.lazy(() => import("./components/account"));
const DisplayReports = React.lazy(() => import("./components/reports"));
const DisplaySingleReport = React.lazy(() => import("./components/reports/displaySingleReport"));
const SearchResults = React.lazy(() => import("./components/search"));
const Role = React.lazy(() => import("./components/admin/role"));
const SubscriptionMatrix = React.lazy(() => import("./components/admin/SubscriptionMatrix"));
//sand
const Configureaqariresources = React.lazy(() => import("./components/admin/configureaqariresources"));
const Subscription = React.lazy(() => import("./components/admin/subscription"));
const PaySubscription = React.lazy(() => import("./components/payment_gateway/PaySubscription"));
const SuccessReturnUrl = React.lazy(() => import("./components/payment_gateway/SuccessReturnUrl"));
const NotifyReturnUrl = React.lazy(() => import("./components/payment_gateway/NotifyReturnUrl"));
const FailureReturnUrl = React.lazy(() => import("./components/payment_gateway/FailureReturnUrl"));
const CancelReturnUrl = React.lazy(() => import("./components/payment_gateway/CancelReturnUrl"));
const RequestCustomReport = React.lazy(() => import("./components/home/requestCustomReport"));
const ApproveCustomReport = React.lazy(() => import("./components/home/approveCustomreport/approveCustomReportList"));
const ConfigureCustomReport= React.lazy(() => import("./components/configure_customreport/configure_custom_report"));

const Fallback = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-black bg-opacity-80 absolute inset-0 top-0 w-screen h-screen z-40">
      <div
        className="sr-only"
        role="progressbar"
        aria-busy="true"
        aria-live="polite"
        aria-label="Loading">
        <span aria-hidden="true">{t("general.loading")}</span>
      </div>
      <div className="not-sr-only absolute inset-0 top-0 w-screen h-screen flex justify-center items-center text-6xl text-reraRed animate-pulse">
        {t("general.loading")}...
      </div>
    </div>
  );
};

function App() {
  const permamantPaths = useRoutes();
  const { i18n } = useTranslation();
  const { token } = useAppSelector(MapStateToHooks);
  const updateLanguage = () => {
    const currLanguage = i18n.language;
    const nextLanguage = currLanguage.startsWith("en") ? "ar" : "en";
    i18n.changeLanguage(nextLanguage, () => {
      document.body.dir = i18n.dir();
    });
  };
  
  React.useEffect(() => {
    const currLanguage = i18n.language;
    const nextLanguage = currLanguage.startsWith("en") ? "en" : "ar";
    i18n.changeLanguage(nextLanguage, () => {
      document.body.dir = i18n.dir();
    });
  }, [i18n]);
  
  // Conditionally render header and footer
  const renderHeaderFooter = () => {
    if (
      window.location.pathname.includes("aqariviewer") // Adjust the path as needed
    ) {
      return null; // Don't render header and footer
    }
    return (
      <>
        <Header updateLanguage={updateLanguage} />
      </>
    );
  };

  const renderHeaderFooter1 = () => {
    if (
      window.location.pathname.includes("aqariviewer") // Adjust the path as needed
    ) {
      return null; // Don't render header and footer
    }
    return (
      <>
          <Footer />
      </>
    );
  };

  
return (
  <>
    {renderHeaderFooter() != null ? (
      <>
        {renderHeaderFooter()}
      <main className="container mx-auto mt-4 p-4 pb-24 shadow-sm rounded-t-md min-h-screen-75">
          <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path="/" element={<DefaultRoute />} />
            <Route path="/home" element={<Home />} />
            <Route path="/home/" element={<Home />} />
            <Route path="/requestsubscription" element={<RequestSubscription />} />
            <Route path="/changePAssword" element={<ChangePassword />} />
            <Route path="/usermanagment/viewUser" element={<ViewUser />} />
            <Route path="/paymentreport" element={<PaymentReport />} />
            <Route path="/aqariviewerreport" element={<AqariviewerReport />} />
            <Route path="/loginreport" element={<LoginReport />} />
            <Route path="/ViewUserProfile" element={<ViewUserProfile />} />
            <Route path="/UserSubscriptionApproval" element={<SubscriptionApprove />} />
            <Route path="/useragreement" element={<UserAgrement />} />
            <Route path="/managenews" element={<ManageNews itemId={"0"} />} />
            <Route path="/registerAdmin" element={<RegisterAdmin />} />
            <Route path="/aqariviewer" element={<AqariViewer />} />
            <Route path="/requestcustomreport" element={<RequestCustomReport />} />
            <Route path="/approvecustomreport" element={<ApproveCustomReport />} />
            <Route path="/configurecustomreport" element={<ConfigureCustomReport />} />
            <Route path="auth">
              <Route index element={<PrivateRoute type="auth" token={token} element={<Auth />} />} />
              <Route path="login" element={<PrivateRoute type="auth" token={token} element={<Auth />} />} />
              <Route path="register" element={<PrivateRoute type="auth" token={token} element={<Register />} />} />
              <Route path="verify" element={<PrivateRoute type="auth" token={token} element={<VerifyOtp />} />} />
              <Route path="forgot-password" element={<PrivateRoute type="auth" token={token} element={<ForgotPassword />} />} />
            </Route>
            <Route path="about">
              <Route index element={<About />} />
              <Route path="partners" element={<Partners />} />
              <Route path="aqari" element={<AboutAqari />} />
              <Route path="vision-mission" element={<P404 />} />
              <Route path="our-approach" element={<P404 />} />
              
            </Route>
            {/* Layout is a Temp solution for Demo */}
            <Route path={permamantPaths.stats}>
              <Route index element={<DisplayReports />} />
              <Route path={`${permamantPaths.stats}/view/:reportId`} element={<DisplaySingleReport />} />
              <Route path={`${permamantPaths.stats}/:reportId`} element={<ReportsLayout />} />
            </Route>
            <Route path="contact-us" element={<Contact />} />
            <Route path="faq" element={<FAQs />} />
            {/* <Route path="gis" element={<GIS />} /> */}
            <Route path="aqari" element={<Aqari />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="media/*" element={<Media />} />
            <Route path="search/:searchTerm" element={<SearchResults />} />
            <Route path="account/*" element={<PrivateRoute type="admin" token={token} element={<Account />} />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<P404 />} />
            {/* by sandeep */}
            <Route path="admin/role" element={<Role />} />
            <Route path="admin/subscription-matrix" element={<SubscriptionMatrix />} />
            <Route path="admin/configure-aqari-resources" element={<Configureaqariresources />} />
            <Route path="admin/subscription" element={<Subscription />} />

            {/*Payment Gateway*/}
            <Route path="payment_gateway/PaySubscription" element={<PaySubscription />} />
            <Route path="payment_gateway/SuccessReturnUrl" element={<SuccessReturnUrl />} />
            <Route path="payment_gateway/NotifyReturnUrl" element={<NotifyReturnUrl />} />
            <Route path="payment_gateway/FailureReturnUrl" element={<FailureReturnUrl />} />
            <Route path="payment_gateway/CancelReturnUrl" element={<CancelReturnUrl />} />  
            {/*Payment Gateway*/}
          </Routes>
          </Suspense>
        </main>
        {renderHeaderFooter1()}
      </>
    ) : (
      <>
          <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path="/aqariviewer" element={<AqariViewer />} />
            {/*Payment Gateway*/}
          </Routes>
          </Suspense>
      </>
    )}
  </>
); 
   
}

export default React.memo(App);

const PrivateRoute = ({
  element,
  type,
  token
}: IPrivateRoutes): React.ReactElement | null => {
  const navigate = useNavigate();
  useEffect(() => {
    if (type === "admin") {
      if (!token) {
        navigate("/auth/login");
      }
    }
    // eslint-disable-next-line
  }, []);
  return element || null;
};

const DefaultRoute = (): React.ReactElement | null => {
  const { token } = useAppSelector(MapStateToHooks);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (token) {
  //     navigate("/account/admin");
  //   }
  //   // eslint-disable-next-line
  // }, []);
  return <Home />;
};

const MapStateToHooks = (state: RootState) => ({
  token: state.auth.token
});
