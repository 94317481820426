import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "../common/actions";
import { accountActions } from "./actions";

export interface AccountState {
  user?: any;
  fetchUserStatus: string
}

const initialState: AccountState = {
  fetchUserStatus: accountActions.GET_USER_INITIAL,
  user: {}
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updateAccount( state, action ) {
      Object.assign( state, action.payload );
    }
  },
  extraReducers: ( builder ) => {
    builder.addMatcher(
      action => logoutAction.match( action ),
      ( state ) => {
        state = initialState;
        return state;
      }
    );
  }
}
);

export const { updateAccount } = accountSlice.actions;
export default accountSlice.reducer;