/* eslint-disable */

export const businessManHandWithLegalLaw = require("./carbon/businessman-hand-with-legal-law.jpg");
export const butterflySculpture = require("./carbon/butterfly-sculpture.jpg");
export const digitalPlanet = require("./carbon/digital-planet.jpg");
export const directorSigningDocuments = require("./carbon/director-signing-documents.jpg");
// export const glassOfficeRoom = require("./carbon/glass-office-room-wall.jpg");
export const glassOfficeRoom = require("./carbon/footer.jpg");
export const handsPassingContractor = require("./carbon/hands-passing-contract.jpg");
export const holographicInterfaces = require("./carbon/holographic-interface.jpg");
export const judgeGavel = require("./carbon/judge-gavel.jpg");
export const bahrainLandscape = require("./carbon/landscape.jpg");
export const laptop = require("./carbon/laptop.jpg");
export const manWithVRMap = require("./carbon/man-with-vr-map.jpg");
export const bahrainArial = require("./carbon/manama-areal.jpg");
export const vectorMap = require("./carbon/vector-map.jpg");

/* eslint-enable */