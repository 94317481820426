import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

export type IconProp = FontAwesomeIconProps["icon"];
interface IconInterface {
    icon: FontAwesomeIconProps["icon"];
    spin?: boolean;
    className?: string;
    size?: SizeProp;
    title?: string;
}

export type SizeProp =
  | "xs"
  | "lg"
  | "sm"
  | "1x"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "10x";

const Icon = ({
  icon,
  spin,
  className,
  size,
  title
}: IconInterface ) => {
  return <FontAwesomeIcon
    icon={icon} spin={spin} fixedWidth
    className={clsx( className )} size={size}
    { ...title ? { title } : {} }
  />;
};

export default Icon;