import { createSlice } from "@reduxjs/toolkit";
import { Paths } from "../../types/Routes.types";
import { Routes } from "../../utils/routeUtils";
import { logoutAction } from "../common/actions";

export interface GlobalConfigState {
    permanentPaths: Paths
}

const initialState: GlobalConfigState = {
  permanentPaths: Routes() as Paths
};

export const globalConfigSlice = createSlice({
  name: "globalConfig",
  initialState,
  reducers: {
  },
  extraReducers: ( builder ) => {
    builder.addMatcher(
      action => logoutAction.match( action ),
      ( state ) => {
        state = initialState;
        return state;
      }
    );
  }
});

export default globalConfigSlice.reducer;