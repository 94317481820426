import clsx from "clsx";
import React from "react";
import Icon from "../Icon";

interface MediaLinksProps {
    [name: string]: string;
}

interface SocialMediaProps {
    name: string;
    links: MediaLinksProps;
    returnType?: "icon" | "link";
    className?: string;
}

const iconsMapping = {
  linkedin: [ "fab", "linkedin" ],
  twitter: [ "fab", "x-twitter" ],
  instagram: [ "fab", "instagram" ],
  facebook: [ "fab", "facebook" ],
  youtube: [ "fab", "youtube" ]
};

export const SocialMedia = ({
  name,
  links,
  returnType = "icon",
  className
}: SocialMediaProps ): JSX.Element => {
  return ( name in links )
    ? ( links[name].length > 0
      ? <a href={links[name]} target="_blank" rel="noopener noreferrer" className={clsx( "flex items-center", className )}>
        <span className="sr-only">social media {name} link</span>
        {returnType === "link"
          ? links[name]
          : <Icon icon={iconsMapping[name]} className="not-sr-only" aria-hidden="true" />}
      </a>
      : <></> ) : <></>;
};

export const ShowAllSocialMediaIcons = ({
  className
}: { className?: string; }) => {
  const [ loading, setLoading ] = React.useState<boolean>( true );
  const [ links, setLinks ] = React.useState<MediaLinksProps>({});
  const getData = () => {
    setLoading( true );
    fetch( "/content/social_media_links.json", {
      headers : {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(( res ) => {
      return res.json();
    }).then(( data ) => {
      setLinks( data );
    }).catch(( err ) => {
      // eslint-disable-next-line
      console.error( err );
      setLinks({});
    }).finally(() => {
      setLoading( false );
    });
  };
  React.useEffect(() => {
    getData();
  }, []);
  return <>
    { loading
      ? <Icon icon="sync" spin={true} aria-hidden="true" />
      : Object.keys( iconsMapping ).map(( name ) => <SocialMedia name={name} key={name} className={className} links={links} /> )
    }
  </>;
};

export default SocialMedia;