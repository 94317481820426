import React from "react";
import ENLogo from "../../assets/images/logo-en.svg";
import { ShowAllSocialMediaIcons } from "../common/socialMedia";
import { glassOfficeRoom as bgImage } from "../../assets/images/staticImages";
import { Link } from "react-router-dom";
import { Timeline } from "react-twitter-widgets";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import clsx from "clsx";
import { useFetchFile } from "../../utils/hooks/useFetchFile";
import { SpecificTranslate } from "../common/reusable/formTranslations";
import RERAlogo from "../../assets/images/RERA_logo-01.png";
import Bahrain2023logo from "../../assets/images/bahrain2023.png";

const path = "/content/contact.json";

const navigation = {
  Categories: [
    { name: "home", href: "/home", id: "menu.home" },
    {
      name: "StartExploring",
      href: "/aqari",
      id: "menu.about.StartExploringTitle",
    },
    // { name: "AboutAqari", href: "/about", id: "menu.about.AboutAqariTitle" },
    // { name: "News", href: "/media/news", id: "menu.media.news" },
    { name: "FAQs", href: "/faq", id: "menu.faq" },
    { name: "Contact Us", href: "/contact-us", id: "menu.contact.title" },
    // { name: "About Us", href: "/about", id: "menu.about.title" },
    // { name: "Partners", href: "/about/partners", id: "menu.about.partnersTitle" },
    // { name: "GIS", href: "/about/gis", id: "menu.GIS" },
    // { name: "News", href: "/media/news", id: "menu.media.news" },
    // { name: "Events", href: "/media/events", id: "menu.media.event" },
    // { name: "Library", href: "/media/library", id: "menu.media.library" }
  ],
  legal: [
    // { name: "Contact Us", href: "/contact-us", id: "menu.contact.title" },
    // { name: "FAQs", href: "/faq", id: "menu.faq" }
  ],
};

const Footer = () => {
  const { t, i18n } = useTranslation();
  const language = React.useMemo(() => {
    return i18n.language.startsWith("en") ? "en-US" : "ar-BH";
  }, [i18n]);
  const lang = i18n.language === "en" ? "en" : "ar";
  const { data } = useFetchFile(path);
  return (
    <>
      <footer className="bg-slate-800" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only" aria-label="Footer">
          Footer
        </h2>
        <div className="w-full flex flex-col items-center space-y-1 not-sr-only">
          <hr className="w-full border-slate-700" />
          <hr className="w-3/4 border-slate-600" />
          <hr className="w-2/3 border-slate-600" />
          <hr className="w-1/2 border-slate-600" />
          <hr className="w-1/3 border-slate-600" />
          <hr className="w-1/4 border-slate-600" />
        </div>
        <div className="relative sm:overflow-hidden w-full">
          <div className="absolute inset-0 not-sr-only" aria-hidden="true">
            <img
              className="h-full w-full object-cover print:max-h-52"
              src={bgImage}
              alt="Bahrain buildings"
              aria-hidden="true"
            />
            <div className="absolute inset-0 bg-slate-900 bg-opacity-90 mix-blend-multiply" />
          </div>
          <div className="relative container mx-auto px-4">
            <div className="w-full pt-12 p-4 flex flex-col flex-wrap container mx-auto">
              <div className="w-full flex flex-col-reverse gap-20 sm:flex-row sm:justify-between">
                <div
                  className={clsx(
                    "w-full flex flex-col flex-none justify-between items-start space-s-0 space-y-4",
                    "sm:w-1/2 sm:flex-row sm:space-s-20 sm:space-y-0",
                    "print:hidden"
                  )}
                >
                  <div className="flex flex-col flex-1">
                    <h3 className="text-lg font-bold text-slate-200 tracking-wider uppercase">
                      {t("menu.contact.title")}
                    </h3>
                    {/* <dl className="mt-8 space-y-6"> */}
                    <dl>
                      <dt>
                        <span className="sr-only">RERA Logo</span>
                      </dt>
                      <dd className="flex text-base text-white gap-2">
                        <a //href="https://www.rera.gov.bh/"
                          href={
                            i18n.language === "ar"
                              ? "https://www.rera.gov.bh/"
                              : "https://www.rera.gov.bh/en/"
                          }
                          className="text-base text-gray-300 hover:text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={RERAlogo} alt="RERA logo" />
                        </a>
                      </dd>
                      <dt>
                        <span className="sr-only">Address</span>
                      </dt>
                      <dd className="flex text-base text-white gap-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.address?.[lang],
                          }}
                        />
                      </dd>
                      <dt>
                        <span className="sr-only">Phone number</span>
                      </dt>
                      <dd className="flex text-base text-white gap-2">
                        <div>{t("contact.phoneLabel")}</div>
                        <div>:</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.phoneFooter?.[lang],
                          }}
                        />
                      </dd>
                      <dt>
                        <span className="sr-only">Fax</span>
                      </dt>
                      <dd className="flex text-base text-white gap-2">
                        <div>{t("contact.faxLabel")}</div>
                        <div>:</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.faxFooter?.[lang],
                          }}
                        />
                      </dd>
                      <dt>
                        <span className="sr-only">Email</span>
                      </dt>
                      <dd className="flex text-base text-white gap-2">
                        <div>{t("contact.emailLabel")}</div>
                        <div>:</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.email?.[lang],
                          }}
                        />
                      </dd>
                      <dt>
                        <span className="sr-only">Timings</span>
                      </dt>
                      {/* <dd className="flex text-base text-white gap-2"> */}
                        {/* <Icon icon="clock" className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" /> */}
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: data?.timings?.[lang],
                          }}
                        /> */}
                      {/* </dd> */}
                    </dl>
                  </div>
                  <div className="flex flex-col flex-1">
                    <h3 className="text-lg font-bold text-slate-200 tracking-wider uppercase">
                      {t("footer.categories")}
                    </h3>
                    <ul
                      role="list"
                      className="mt-4 space-y-4"
                      aria-label="footer links"
                    >
                      {navigation.Categories.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                            className="text-base text-gray-300 hover:text-white"
                          >
                            {t(item.id)}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <a //href="https://www.rera.gov.bh/"
                          href={
                            i18n.language === "ar"
                              ? "https://www.rera.gov.bh/"
                              : "https://www.rera.gov.bh/en/"
                          }
                          className="text-base text-gray-300 hover:text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("menu.about.aboutReraTitle")}
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="flex flex-col flex-1 flex-shrink">
                  <h3 className="text-lg font-semibold text-gray-400 tracking-wider uppercase">&nbsp;</h3>
                  <ul role="list" className="mt-4 space-y-4" aria-label="footer links">
                    {navigation.legal.map(( item ) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-300 hover:text-white">
                          {t( item.id )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
                </div>
                <div className="sm:w-1/2 flex flex-1 flex-col items-start justify-start">
                  <a
                    href="https://rera.gov.bh/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group"
                  >
                    <figure className="grayscale brightness-200 group-hover:grayscale-0 print:grayscale-50 hidden print:block">
                      <img
                        src={ENLogo}
                        alt="RERA logo"
                        className="h-32 max-h-40"
                        loading="lazy"
                      />
                    </figure>
                  </a>
                  <div className="hidden print:hidden w-full">
                    <Timeline
                      // onLoad={() => toogleTweetsLoading( false )}
                      dataSource={{
                        sourceType: "profile",
                        screenName: "ReraBh",
                      }}
                      options={{
                        theme: "dark",
                        width: "100%",
                        height: "350",
                        lang: language,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                <div className="flex items-center space-s-6 md:order-2 not-sr-only">
                  <ShowAllSocialMediaIcons className="text-gray-400 hover:text-white" />
                </div>
                <div>
                  <img
                    src={Bahrain2023logo}
                    alt="RERA logo"
                    className="h-20 max-h-24"
                    loading="lazy"
                  />
                </div>
                <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                  {t("footer.copyRight", { year: 2024 })}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center space-y-1 not-sr-only">
          <hr className="w-1/4 border-slate-600" />
          <hr className="w-1/3 border-slate-600" />
          <hr className="w-1/2 border-slate-600" />
          <hr className="w-2/3 border-slate-600" />
          <hr className="w-3/4 border-slate-600" />
          <hr className="w-full border-slate-700" />
        </div>
      </footer>
    </>
  );
};
export default Footer;
