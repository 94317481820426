import axios, { AxiosResponse } from "axios";
import config from "../../config.json";
const API_BASE_URL = config.GLOBAL_SETTINGS.Host;
export const fetchSubscriptionData = async (): Promise<AxiosResponse> => {
  const response = await axios.get(`${API_BASE_URL}/ManageSubscriptionRequest`);
  return response;
};
export const fetchSubscriptionData1 = async (id:string): Promise<AxiosResponse> => {
  const response = await axios.get(`${API_BASE_URL}/ManageSubscriptionRequest/${id}`);
  return response;
};
const api = axios.create({
  baseURL: API_BASE_URL,
});
export const DownloadDocument = async (docname: string,tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await api.get(
      `${API_BASE_URL}/ManageDocuments/DownloadDocument?id=${docname}`,      
      {
        headers: {
          authorized: tokentobesent
        },
        responseType: 'blob',
      }
    );
    return response;
  } catch (error) {
    
    throw error;
  }

};


export const fetchUserData = async (id: number, tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
     // `${API_BASE_URL}/ManageUserProfile/GetUserDetails?reg_gid=${regId}&userlogin_gid=${id}`,
      `${API_BASE_URL}/ManageUser/GetUserListWithPlanDL?id=`+id,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
    
    throw error;
  }

};
// export const fetchUserData = async (id:any): Promise<AxiosResponse> => {
//   const response = await axios.get(`${API_BASE_URL}/ManageUser/GetUserListWithPlanDL?id=`+id);
//   return response;
// };



// export const fetchUserDataById = async (id:string, regId:string): Promise<AxiosResponse> => {
//   const response = await axios.get(`${API_BASE_URL}/ManageUserProfile/GetUserDetails?reg_gid=${regId}&userlogin_gid=${id}`);
//   return response;
// };

export const fetchUserDataById = async (id: string, regId: string, tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/ManageUserProfile/GetUserDetails?reg_gid=${regId}&userlogin_gid=${id}`,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
    
    throw error;
  }
};



// export const UpdateProfile = async (userData: any): Promise<AxiosResponse> => {
//   const response = await axios.post(`${API_BASE_URL}/ManageUserProfile/UpdateProfile`, userData);
//   return response;
// };

export const UpdateProfile = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/ManageUserProfile/UpdateProfile`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const UpdateProfileforreportNeedMoredetail = async (userData: any,tokentobesent:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/ManageCustomReports/approvestatusofreportforneedmoredwtail`,
      userData,
      {
        headers: {
          authorized: tokentobesent
        }
      }
      
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

// export const UserActivation = async (userData: any): Promise<AxiosResponse> => {
//   const response = await axios.post(`${API_BASE_URL}/ManageUserProfile/UserActivation`, userData);
//   return response;
// };
export const UserActivation = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/ManageUserProfile/UserActivation`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const fetchAquariResources = async (): Promise<AxiosResponse> => {
  const response = await axios.get(`${API_BASE_URL}/ManageAqariResources`);
  return response;
};
export const postChangePassword = async (userData: any): Promise<AxiosResponse> => {
  const response = await axios.post(`${API_BASE_URL}/ChangePassword`, userData);
  return response;
};
export const LoginAction = async (userData: any): Promise<AxiosResponse> => {
  const response = await axios.post(`${API_BASE_URL}/Login`, userData);
  return response;
};
export const RegistrationAction = async (userData: any): Promise<AxiosResponse> => {
  const response = await axios.post(`${API_BASE_URL}/RegisterUser`, userData);
  return response;
};