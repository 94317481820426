import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import { IHttp, IUrl, IHttpConfiguration } from "./fetchUtils.types";
import config from "../config.json";

/* RTKQ Fetch Helper Methods */

export const urlBuilderRTKQ = ( path:string | string[], queryParam?: {[key:string]: any}): string => {
  let url = "";

  if ( Array.isArray( path )){
    url = url.concat( path.join( "/" ));
  } else {
    url = url.concat( path );
  }

  if ( queryParam && Object.keys( queryParam ).length > 0 ) {
    url = url.concat( `?${encodeQueryParamsRTKQ( queryParam )}` );
  }

  return url;
};

export const encodeQueryParamsRTKQ = ( queryObject: { [key: string]: string | number }): string => {
  const queryParams = [];
  for ( const param in queryObject ) {
    if ( queryObject[param] === null || typeof param === "undefined" ) {
      continue;
    }
    queryParams.push( `${encodeURIComponent( param )}=${encodeURIComponent( queryObject[param])}` );
  }
  return queryParams.join( "&" );
};

/* Generic Fetch Helper Methods */

export const extractMessage = ( data: any, fallback = "" ): string => {
  try {
    if ([ "undefined", "null" ].includes( typeof data )){
      return fallback;
    } else {
      return data?.Message ?? data?.message ?? ( typeof data === "string" ? data : undefined ) ?? fallback;
    }
  } catch ( e ) {
    return fallback;
  }
};

export const extractError = ( error_obj : any, fallbackError = "Network Error" ): string => {
  try {
    return extractMessage( error_obj?.data, error_obj?.response ) || extractError( error_obj?.data, undefined ) ||
      extractMessage( error_obj, fallbackError );
  } catch ( e ) {
    return fallbackError;
  }
};

/* Axios Fetch Helper Methods */

const enAPI = `${config.API_gateway}/`;

const basicHeaders = {
  "Accept": "application/json, text/plain, */*",
  "Content-Type": "application/json"
};

export const urlBuilder = ( urlTerms: IUrl ): string => {
  if ( typeof urlTerms === "string" ) {
    return urlTerms.startsWith( "http" ) ? urlTerms : enAPI.concat( urlTerms );
  } else if ( Array.isArray( urlTerms )){
    let url = "";
    if ( Array.isArray( urlTerms?.[0])){
      url = enAPI.concat( urlTerms?.[0].join( "/" ));
      if ( Array.isArray( urlTerms?.[1])){
        url = url.concat( `?${urlTerms?.[1]?.join( "&" )}` );
      }
      return url;
    } else {
      return enAPI.concat( urlTerms.join( "/" ));
    }
  } else {
    return enAPI;
  }
};

export class Http implements IHttp {
  private client: AxiosInstance;
  private defaultURL: IUrl = enAPI;

  constructor( configuration:IHttpConfiguration ) {
    this.client = axios.create({
      timeout: configuration?.timeout ?? 31000,
      headers: {
        ...basicHeaders,
        ...(
          ( typeof configuration?.getState !== "undefined" || configuration?.token )
            ? { "Authorization": configuration?.getState?.()?.auth?.token ?? configuration.token ?? "" }
            : {}
        ),
        ...( configuration?.headers ? configuration.headers : {})
      }
    });
    if ( configuration?.url ){
      this.defaultURL = configuration.url;
    }
    axiosRetry( this.client, { retries: 2, retryDelay: axiosRetry.exponentialDelay, shouldResetTimeout: true });
  }

  setURL = ( url: IUrl ) => {
    this.defaultURL = url;
  };

  GET = ( url: IUrl = this.defaultURL ) => {
    return this.client.get( urlBuilder( url ));
  };

  POST = ( url: IUrl = this.defaultURL, data?: any ) => {
    return this.client.post( urlBuilder( url ), data ?? {});
  };

  PUT = ( url: IUrl = this.defaultURL, data?: any ) => {
    return this.client.put( urlBuilder( url ), data ?? {});
  };

  DELETE = ( url: IUrl = this.defaultURL, data?: any ) => {
    if ( data ){
      return this.client.delete( urlBuilder( url ), { data });
    } else {
      return this.client.delete( urlBuilder( url ?? this.defaultURL ));
    }
  };
}