import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../modules/auth/actionCreators";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SessionLogout } from "../../home/viewUser/apiService";
import { RootState } from "../../../store";

interface IStateProps {
  isAuthorised: boolean,
  fetchingVD: Record<string, boolean | undefined>;
  viewData: Record<string, any[]>;
  fetchVDError: Record<string, any>;
  gid: string;
  rid: string;
  email:string;
  tokenact:string
}

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const idd=sessionStorage.getItem("setTimeout")
  clearTimeout(idd ||'');
  const {
    gid, rid,email,tokenact
  } = useAppSelector(mapStateHook);
  React.useEffect(() => {
    dispatch( logout( navigate ));
     SessionLogout({
      "username": email,
      "password": "",
      "token": tokenact
    }, tokenact||"");
  }, [ dispatch, navigate ,SessionLogout]);
  
  return null;
};

const mapStateHook = (state: RootState): IStateProps => {
  return {
   // userName: state.account.user?.UserId || "",
    isAuthorised: state.auth.token ? true : false,
    fetchingVD: state.reports.fetchingVD,
    viewData: state.reports.viewData,
    fetchVDError: state.reports.fetchVDError,
    gid: state.auth.gid,
    rid: state.auth.rid,
    email:state.auth.email,
    tokenact:state.auth.tokenact
  };
};

export default Logout;