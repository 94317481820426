export const aboutUs = [
  // { id: "home", href: "/home", icon: "home" },
  { id: "StartExploring", href: "/aqari", icon: "map-location-dot" },
  // { id: "AboutAqari", href: "/about", icon: "location-dot" },
  // { id: "mediaNews", href: "/media/news", icon: "newspaper" },
  { id: "faq", href: "/faq", icon: "question" },
  { id: "contact", href: "/contact-us", icon: "envelope" },
  // { id: "paysubscription", href: "/paysubscription", icon: "handshake" },

  // { id: "customsubscription", href: "/customsubscription", icon: "handshake" },
  // { id: "viewsubscription", href: "/viewsubscription", icon: "handshake" },
  // { id: "subscriptionSuccess", href: "/subscriptionSuccess", icon: "handshake" },
  // { id: "subscriptionFailed", href: "/subscriptionFailed", icon: "handshake" },
  // { id: "subscriptionCancel", href: "/subscriptionCancel", icon: "handshake" },

  // { id: "UserSubscriptionApproval", href: "/UserSubscriptionApproval", icon: "handshake" },
  // { id: "aboutRera", href: "/staticLink", icon: "fingerprint" },
  // { id: "partners", href: "/about/partners", icon: "handshake" },
  // { id: "faq", href: "/faq", icon: "question" }
];
export const contactAndFaqs = [
  { id: "contact.title", href: "/contact-us", icon: "envelope" },
  { id: "faq", href: "/faq", icon: "question" },
];
export const media = [
  // { id: "media.news", href: "/media/news", icon: "newspaper" }
  // { id: "media.event", href: "/media/events", icon: "calendar-day" },
  // { id: "media.library", href: "/media/library", icon: "book" }
];
export const adminRole = [
  // { id: "role", href: "/admin/role", icon: "fas fa-user-alt" },
  {
    id: "subscriptionconfiguration",
    href: "/admin/subscription",
    icon: "arrow-right",
  },
  {
    id: "subscriptionmatrix",
    href: "/admin/subscription-matrix",
    icon: "arrow-right",
  },
  // by sand
  // {
  //   id: "configureAqari",
  //   href: "/admin/configure-aqari-resources",
  //   icon: "arrow-right",
  // },
  { id: "manageUser", href: "/usermanagment/viewUser", icon: "arrow-right" },
  {
    id: "UserSubscriptionApproval",
    href: "/UserSubscriptionApproval",
    icon: "arrow-right",
  },
  { id: "changePassword", href: "/changePassword", icon: "arrow-right" },
  { id: "ViewUserProfile", href: "/ViewUserProfile", icon: "arrow-right" },
  { id: "adminregister", href: "/registerAdmin", icon: "arrow-right" },
  { id: "paymentreport", href: "/paymentreport", icon: "arrow-right" },
  // { id: "manageNews", href: "/managenews", icon: "newspaper" },
  { id: "loginreport", href: "/loginreport", icon: "arrow-right" },
  { id: "aqariviewer", href: "/aqariviewerreport", icon: "arrow-right" },
  // { id: "approvecustomreport", href: "/approvecustomreport", icon: "arrow-right" },
  // { id: "managecustomreport", href: "/configurecustomreport", icon: "arrow-right" },
];

export const userRole = [
  // { id: "role", href: "/admin/role", icon: "fas fa-user-alt" },
  { id: "ViewUserProfile", href: "/ViewUserProfile", icon: "arrow-right" },
  { id: "changePassword", href: "/changePassword", icon: "arrow-right" },
  {
    id: "requestsubscription",
    href: "/requestsubscription",
    icon: "arrow-right",
  },
  {
    id: "requestcustomreport",
    href: "/requestcustomreport",
    icon: "arrow-right",
  },
];

//Payment Gateweay
export const paymentGateway = [
  {
    id: "PaySubscription",
    href: "/payment_gateway/PaySubscription",
    icon: "arrow-right",
  },
];
//Payment Gateweay
export const getTitle = (pathname) => {
  // if (pathname?.startsWith("/home")) {
  //   return "menu.home";
  // } else
  if (pathname?.startsWith("/auth/login")) {
    return "auth.login";
  } else if (pathname?.startsWith("/auth/register")) {
    return "auth.register";
  } else if (pathname?.startsWith("/auth/forgot-password")) {
    return "auth.forgotPasswordHeading";
  } else if (pathname?.startsWith("/about/verify")) {
    return "auth.verifyOTP";
  } else if (pathname?.startsWith("/about/partners")) {
    return "menu.about.partnersTitle";
  } else if (pathname?.startsWith("/about/aqari")) {
    return "menu.about.AboutAqariTitle";
  } else if (pathname?.startsWith("/about")) {
    return "menu.about.title";
  } else if (pathname?.startsWith("/contact")) {
    return "menu.contact.title";
  } else if (pathname?.startsWith("/faq")) {
    return "menu.faq";
  } else if (pathname?.startsWith("/stats")) {
    return "menu.stats.title";
  } else if (pathname?.startsWith("/gis")) {
    return "menu.GIS";
  } else if (pathname?.startsWith("/media/news")) {
    return "menu.media.news";
  } else if (pathname?.startsWith("/media/event")) {
    return "menu.media.event";
  } else if (pathname?.startsWith("/media/library")) {
    return "menu.media.library";
  } else if (pathname?.startsWith("/paysubscription")) {
    return "menu.paysubscription";
  } else if (pathname?.startsWith("/requestsubscription")) {
    return "menu.requestsubscription";
  } else if (pathname?.startsWith("/customsubscription")) {
    return "menu.customsubscription";
  } else if (pathname?.startsWith("/viewsubscription")) {
    return "menu.viewsubscription";
  } else if (pathname?.startsWith("/subscriptionSuccess")) {
    return "menu.subscriptionSuccess";
  } else if (pathname?.startsWith("/subscriptionFailed")) {
    return "menu.subscriptionFailed";
  } else if (pathname?.startsWith("/subscriptionCancel")) {
    return "menu.subscriptionCancel";
  } else if (pathname?.startsWith("/changePassword")) {
    return "menu.changePassword";
  } else if (pathname?.startsWith("/userPassword")) {
    return "menu.userPassword";
  } else if (pathname?.startsWith("/admin/subscription-matrix")) {
    return "subscriptiontype.subscriptionMatrix";
  } else if (pathname?.startsWith("/admin/subscription")) {
    return "subscriptiontype.SubscriptionType";
  } else if (pathname?.startsWith("/usermanagment/viewUser")) {
    return "menu.adminRole.manageUserTitle";
  }
  return "";
};
