/* eslint-disable */
import React from "react";
import rolesJson from "../../../roles_and_permissions.json";
import { IPermissionContextState, RoleType, IRolesAndPermissions } from "./Permissions.types";
import { useSelector } from "react-redux";

export const PermissionContext = React.createContext<IPermissionContextState | null>( null );

interface IPermissionContextProviderProps {
  children?: React.ReactNode;
}

export const PermissionContextProvider = ({ children }: IPermissionContextProviderProps) => {
  const { user = {} } : any = useSelector(( state: any ) => ({
    user: state?.account?.user
  }));

  const permissions: string[] = (() => {
    let permissionsList: string[] = [];
    if ( Array.isArray( user.RolesAttached )){
      // eslint-disable-next-line
      const _dummy = user.RolesAttached?.forEach(( roleId : RoleType ) => {
        const roleInfo = ( rolesJson.find(( roleObj ) => roleObj?.RoleId === roleId )) as IRolesAndPermissions;
        permissionsList = [ ...permissionsList, ...( roleInfo?.RolePermissions ?? []) ];
      });
    }
    return permissionsList;
  })();

  const provider = {
    permissions
  } as IPermissionContextState;

  return <PermissionContext.Provider value={provider}>{children}</PermissionContext.Provider>;
};

