import { Amplify, Auth } from "aws-amplify";
import { Dispatch } from "redux";

import config from "../../config.json";
import reraApi from "../../store/apis";
import { AppThunk } from "../../store/hooks";
import { logoutAction } from "../common/actions";
import { updateAuth } from "./reducer";
import { authActions } from "./actions";
import { NavigateFunction } from "react-router-dom";
import { toMoment } from "../../utils/dateUtils";
import { getUserDetails } from "../account/actionCreators";
import { alertAndHide } from "../../utils/alertUtils";
import i18n from "../../locales/i18n";
import { resetReportsSlice } from "../reports/reducer";

// let unAuthenticatedUser: any = null;

export const login = ( navigate: NavigateFunction, username: string, password: string ): AppThunk =>
  async ( dispatch: Dispatch<any>, getState: any ): Promise<any> => {
    try {
      dispatch(
        updateAuth({
          loginStatus: authActions.LOGIN_PROCESSING,
          loginErrorMsg: undefined,
        })
      );
      const user = await Auth.signIn(username, password);
      /* eslint-disable no-console */
      console.log(user);
      /* eslint-enable no-console */
      const {
        globalConfig: { permanentPaths },
      } = getState();
      /* eslint-disable no-console */
      console.log(permanentPaths);
      /* eslint-enable no-console */
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(permanentPaths.resetPassword.path);
        return;
      }

      dispatch(
        updateAuth({
          token: user?.signInUserSession.idToken.jwtToken,
          // refreshToken: user?.signInUserSession.idToken.refreshToken,
          loginTime: toMoment(null, "epoch"),
          lastActivity: toMoment(null, "epoch"),
          sessionActive: true,
          loginStatus: authActions.LOGIN_SUCCESS,
          username,
        })
      );

      dispatch(resetReportsSlice());

      dispatch(getUserDetails(username, navigate));
    } catch ( error ) {
      dispatch( updateAuth({
        loginErrorMsg: ( error as Error ).message || JSON.stringify( error ),
        loginStatus: authActions.LOGIN_FAILURE
      }));
    }
  };

export const register = ( navigate: NavigateFunction, username: string, password: string, fullname: string, email: string ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({
        signUpStatus: authActions.SIGN_UP_PROCESSING,
        registrationErrorMsg: undefined,
        destinationEmail: undefined
      }));

      const { user, codeDeliveryDetails }: Record<string, any> = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          name: fullname
        }
      });

      dispatch( updateAuth({
        destinationUsername: user?.username,
        destinationEmail: email || codeDeliveryDetails?.Destination,
        signUpStatus: authActions.SIGN_UP_SUCCESS,
        confirmTOTPForSignIn: undefined
      }));

      // const { permanentPaths } = getState().globalConfig;
      navigate( "/auth/verify" );

    } catch ( error ) {
      dispatch( updateAuth({
        registrationErrorMsg: ( error as Error ).message || JSON.stringify( error ),
        signUpStatus: authActions.SIGN_UP_FAILURE,
        destinationEmail: undefined
      }));
    }
  };

export const verifyRegistration = ( username: string, code: string, restart: () => void ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({
        confirmSignUpErrorMsg: undefined,
        confirmSignUpStatus: authActions.CONFIRM_SIGN_UP_PROCESSING,
        registrationResendCodeStatus: authActions.RESEND_INITIAL
      }
      ));
      await Auth.confirmSignUp( username, code );
      dispatch( updateAuth({
        destinationUsername: undefined,
        destinationEmail: undefined,
        confirmSignUpStatus: authActions.CONFIRM_SIGN_UP_SUCCESS
      }));
      restart();
    } catch ( error ) {
      dispatch( updateAuth({
        confirmSignUpErrorMsg: ( error as Error ).message || JSON.stringify( error ),
        confirmSignUpStatus: authActions.CONFIRM_SIGN_UP_FAILURE
      }));
    }
  };

export const resendRegistrationCode = ( username: string ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({
        resendSignUpErrorMsg: undefined,
        confirmSignUpErrorMsg: undefined,
        registrationResendCodeStatus: authActions.RESEND_PROCESSING
      }));
      await Auth.resendSignUp( username );
      dispatch( updateAuth({
        registrationResendCodeStatus: authActions.RESEND_SUCCESS
      }));
    } catch ( error ) {
      dispatch( updateAuth({
        resendSignUpErrorMsg: ( error as Error ).message,
        registrationResendCodeStatus: authActions.RESEND_FAILURE
      }));
    }
  };

export const forgotPassword = ( navigate: NavigateFunction, username: string ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({
        forgotPwdErrorMsg: undefined,
        forgotPwdStatus: authActions.FORGOT_PWD_PROCESSING
      }));
      // Send confirmation code to user's email
      const deliveryDetails = await Auth.forgotPassword( username );
      dispatch( updateAuth({
        forgotPwdStatus: authActions.FORGOT_PWD_SUCCESS,
        forgotPwdSubmitStatus: undefined,
        forgotPwdSubmitErrorMsg: undefined,
        destinationUsername: username,
        destinationEmail: username.includes( "@" ) ? username : deliveryDetails?.Destination
      }));
      navigate( "/auth/verify" );
    } catch ( error ) {
      dispatch( updateAuth({
        forgotPwdErrorMsg: ( error as Error ).message,
        forgotPwdStatus: authActions.FORGOT_PWD_FAILURE
      }));
    }
  };

export const forgotPasswordResendCode = ( username: string ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({
        forgotPwdSubmitErrorMsg: undefined,
        forgotPwdResendCodeErrorMsg: undefined,
        forgotPwdResendCodeStatus: authActions.FORGOT_PWD_RESEND_CODE_PROCESSING
      }));
      // Resend confirmation code to user's email
      await Auth.forgotPassword( username );

      dispatch( updateAuth({
        forgotPwdResendCodeStatus: authActions.FORGOT_PWD_RESEND_CODE_SUCCESS
      }));
    } catch ( error ) {
      dispatch( updateAuth({
        forgotPwdResendCodeErrorMsg: ( error as Error ).message,
        forgotPwdResendCodeStatus: authActions.FORGOT_PWD_RESEND_CODE_FAILURE
      }));
    }
  };

export const confirmPassword = ( username: string, verificationCode: string, newPassword: string, restart: ()=>void ): AppThunk =>
  async ( dispatch ) => {
    try {
      dispatch( updateAuth({ forgotPwdSubmitErrorMsg: null,
        forgotPwdSubmitStatus: authActions.FORGOT_PWD_SUBMIT_PROCESSING }));
      // Collect confirmation code and new password, then
      await Auth.forgotPasswordSubmit( username, verificationCode, newPassword );
      dispatch( updateAuth({
        forgotPwdSubmitStatus: authActions.FORGOT_PWD_SUBMIT_SUCCESS,
        destinationUsername: undefined,
        destinationEmail: undefined
      }));
      restart();
    } catch ( error: any ) {
      dispatch( updateAuth({
        forgotPwdSubmitErrorMsg: error.message || JSON.stringify( error ),
        forgotPwdSubmitStatus: authActions.FORGOT_PWD_SUBMIT_FAILURE
      }));
    }
  };

export const changePassword = ( oldPassword:string, newPassword:string, navigate: any ): AppThunk => {
  return async( dispatch ): Promise<any> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const passwordChangeRes = await Auth.changePassword( user, oldPassword, newPassword );
      if ( passwordChangeRes === "SUCCESS" ){
        dispatch( logout( navigate ));
      }
      alertAndHide({ title: i18n.t( "auth.passwordChanged" ) });
    } catch ( err: any ){
      alertAndHide({ title: i18n.t( "general.requestFailed" ) });
    }
  };
};

export const logout = ( navigate?: NavigateFunction ):AppThunk =>
  async ( dispatch ) => {
    try {
     // Auth.signOut();
      // const { permanentPaths } = getState().globalConfig;
      dispatch( logoutAction());
      dispatch( reraApi.util.resetApiState());
      if ( navigate ){
        navigate?.( "/auth/login" );
      } else {
        window.location.replace( "/auth/login" );
      }
    } catch ( error ) {
      // eslint-disable-next-line no-console
      console.log( "error signing out: ", error );
    }
  };

export const updateAuthReducer = ( data: Record<string, any> ):AppThunk => ( dispatch ) => {
  dispatch( updateAuth( data ));
};

export const resetAuthProps = ( propsToReset: Record<string, unknown | never> ): AppThunk => {
  return ( dispatch ) => {
    dispatch( updateAuth( propsToReset ));
  };
};


