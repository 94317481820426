
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ReportsConfig = {
  "property-summary": {
    Title: {
      en: "Property Summary - No.of Properties by Property Type by Postal Code",
      ar: "ملخص الخاصية - عدد الخصائص حسب نوع الخاصية حسب الرمز البريدي"
    },
    Description: {
      en: `This interactive dashboard is a dummy report that shows Property Summary for the number of properties by property type by postal code. 
      It shows the breakup of properties into categories like Parks, Apartments, Hotels, Villas etc. 
      This is a sample map type report pulled dynamically from ArcGIS system.`,
      ar: `لوحة المعلومات التفاعلية هذه عبارة عن تقرير وهمي يعرض ملخص الخاصية لعدد الخصائص حسب نوع الخاصية 
      حسب الرمز البريدي. يعرض تقسيم العقارات إلى فئات مثل المتنزهات والشقق والفنادق والفيلات وما إلى ذلك.
      هذا تقرير نموذجي لنوع الخريطة يتم سحبه ديناميكيًا من نظام ArcGIS.`
    },
    DashboardLink: "https://arcgis-poc.cwdl.cloudwick.com/arcportal/apps/dashboards/a38bca3ea8314484835576b47afe6acb"
  },
  "land-use-area": {
    Title: {
      en: "Land Use and Built-up area Intersection",
      ar: "استخدامات الأراضي وتقاطع المساحات المبنية"
    },
    Description: {
      en: `This interactive dashboard is a dummy report that shows Land Use and Built-up area Intersection. 
      It shows the breakup of land usage into residential units like Parks, Apartments, Hotels, Villas etc. 
      and other categories like forest, recreation grounds, military, farm, cemetery etc.
      This is a sample map type report pulled dynamically from ArcGIS system.`,
      ar: ` تقرير وهمي يعرض تقاطع استخدام الأراضي ومنطقة المباني. يُظهر تقسيم استخدام الأراضي إلى وحدات سكنية
       مثل المتنزهات والشقق والفنادق والفيلات وما إلى ذلك وفئات أخرى مثل الغابات ومناطق الترفيه والجيش والمزرعة والمقبرة وما إلى ذلك.
      هذا تقرير نموذجي لنوع الخريطة يتم سحبه ديناميكيًا من نظام ArcGIS.`
    },
    DashboardLink: "https://arcgis-poc.cwdl.cloudwick.com/arcportal/apps/dashboards/43d5e362a7644bfdba304df79fddd198"
  },
  "rental-prices": {
    Title: {
      en: "Rental Price by Postal Code",
      ar: "سعر الإيجار حسب الرمز البريدي"
    },
    Description: {
      en: `This interactive dashboard is a dummy report that shows Rental Price by Postal Code. 
      It shows the breakup of rental prices across the Kingdom of Bahrain by postal code. You may select the layers on the map to get more specific information.
      This is a sample map type report pulled dynamically from ArcGIS system.`,
      ar: `لوحة المعلومات التفاعلية هذه عبارة عن تقرير وهمي يعرض سعر الإيجار حسب الرمز البريدي. يوضح تقسيم أسعار الإيجارات في
       جميع أنحاء مملكة البحرين حسب الرمز البريدي. يمكنك تحديد الطبقات على الخريطة للحصول على معلومات أكثر تحديدًا.
      هذا تقرير نموذجي لنوع الخريطة يتم سحبه ديناميكيًا من نظام ArcGIS.`
    },
    DashboardLink: "https://arcgis-poc.cwdl.cloudwick.com/arcportal/apps/dashboards/573f9cc00d3a4ae88be7dc846cac962b"
  }
};
type ParamTypes = keyof typeof ReportsConfig;

const ReportsLayout = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const { reportId = "report1" } = useParams();
  const lang = i18n.language === "en" ? "en" : "ar";

  return <div className="flex flex-col gap-8 divide-y-2 divide-gray-400">
    <div className="flex justify-between items-center sm:flex-row px-4">
      <div className="content flex flex-col w-full">
        <h1 className="text-2xl font-semibold">{ReportsConfig[reportId as ParamTypes]?.Title[lang] }</h1>
        <details className="text-gray-500 my-2 mb-8">
          <summary className="text-sm font-semibold">{t( "general.description" ) }</summary>
          <p>{ReportsConfig[reportId as ParamTypes]?.Description[lang] }</p>
        </details>
        <iframe
          loading="eager"
          className="border-solid border-black shadow-md focus:border-reraGray rounded-sm h-[100vh]"
          src={ReportsConfig[reportId as ParamTypes]?.DashboardLink}
        />
      </div>
    </div>
  </div>;
};

export default ReportsLayout;