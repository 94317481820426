import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend( relativeTime ).extend( utc ).extend( customParseFormat );
export const DefaultTimestampFormat = "DD MMM, YYYY hh:mm A";
export const ArDefaultTimestampFormat = "A mm:hh DD MMM, YYYY";
export const date_format = ({ formatParams:{ val: { year: "numeric", month: "long", day: "numeric" } } });
export const datetime_format = ({ formatParams:{ val: { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" } } });
export const AllMonths = [ "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December" ];
// ------------------------------------------------------------------------------------------------------------------------------------------------------
/*  VERY IMP README (5mins read):
    This note is very important while reconciling in-between UTC and local times with clarity and writting helper utils accurately.
    Difference in-between any value passed to dayjs() & dayjs.utc(), i.e. ['foo' as in `dayjs(foo)` OR `dayjs.utc(foo)`] :
*/
/*
    CASE 1:
    IF `foo` has UTC information [Ex.'Z'(ISO format), JS Date format (new Date()), unix-date/epoch (ticks)] is PRESENT :
    `dayjs(foo)` will churn-out an object with time in users browser timezone, BUT THIS IS CALCULATED from the passed UTC offset internally.
    Meaning, all `dayjs(foo).format(<string>)` will display local time only while
    the `dayjs(foo).toISOString()` will be the represent the time of `foo` itself as originally passed.
    And if any value passed to `dayjs.utc(foo)`, it is treated as UTC time by default and `dayjs.utc(foo).format(<string>)`
    will give only UTC values of the passed `foo`. No local values.
    Ex. uncomment and try this snippet below to see the contrast : (with UTC info)
    console.log( dayjs("2020-01-20T08:37:57.000Z").format(DefaultTimestampFormat), dayjs.utc("2020-01-20T08:37:57.000Z").format(DefaultTimestampFormat)  )
*/
/*
    CASE 2:
    IF the UTC information is ABSENT in `foo` [i.e. its a random string in some format] :
    `dayjs(foo)` returns an object whose time is ASSUMED to be local browser time. The corresponding UTC time is calculated internally but not displayed.
    Meaning, The `dayjs(foo).toISOString()` is different from the time instance that `foo` represents.
    And if the value is passed to `dayjs.utc(foo)` it is treated as UTC time by default and `dayjs.utc(foo).format(<string>)`
    will produce the passed value of `foo` itself. To get the local value it has to be put through `.local()`
    Ex. uncomment and try this snippet below to see the contrast : (No UTC info)
    console.log(dayjs("2020-01-20 08:37:57").format(DefaultTimestampFormat), dayjs.utc("2020-01-20 08:37:57").format(DefaultTimestampFormat))
*/
/*
    In short, we can say from documentation :
    -> By default, Day.js parses and displays in local time.
    -> If you want to parse or display in UTC, you can use dayjs.utc() instead of dayjs().
        Methods:
        dayjs.utc dayjs.utc(dateType?: string | number | Date | Dayjs, format? string)
        Returns a Dayjs object in UTC mode.
        Use UTC time .utc()
        Returns a cloned Dayjs object with a flag to use UTC time.
        Use local time .local()
        Returns a cloned Dayjs object with a flag to use local time.
        isUTC mode .isUTC()
        Returns a boolean indicating current Dayjs object is in UTC mode or not.
*/
// ------------------------------------------------------------------------------------------------------------------------------------------------------

const getResponseDatetimeFormat = ( datetime ) => {
  switch ( true ) {
  case /\.\d{2,}$/.test( datetime ):
    return "YYYY-MM-DD HH:mm:ss.SSS";
  case /:\d{2}/.test( datetime ):
    return "YYYY-MM-DD HH:mm:ss";
  default:
    return "YYYY-MM-DD HH:mm:ss";
  }
};

const getDefFormat = ( lang = "en" ) => lang === "en" ? DefaultTimestampFormat : ArDefaultTimestampFormat;

// Since this util is mostly used to convert UTC dates incoming from server to local ones ,
// the mode of parsing `dateValue` is defaulted to be UTC.
// export const format

export const convertToLocalTime = ( dateValue, lang = "en", formatString = getDefFormat( lang ), type = "absolute" ) => {
  if ( !dateValue ) {
    return "";
  }
  //utc-parsing mode then converting to local object
  let receivedTime = /^\d{10}$/.test( dateValue )
    ? dayjs.unix( dateValue )
    : dayjs.utc( dateValue, getResponseDatetimeFormat( dateValue )).local();

  if ( !receivedTime.isValid()){
    try {
      receivedTime = dayjs( dateValue );
    } catch ( ex ){
      receivedTime = dateValue;
    }
  }
  if ( type === "relative" ) {
    return receivedTime.fromNow();
  } else {
    return receivedTime.format( formatString );
  }
};

//Covert To UTC Time without zeros for the api service calling
export const convertToUTCTimeNoZeros = ( dateValue ) => {
  if ( !dateValue ) {
    return "";
  }
  //local-parsing mode then converting to utc object
  const receivedTime = dayjs( dateValue ).utc();
  // Since ISO is a standard format to be passed to server, it stands out as a built-in format.
  return `${receivedTime.toISOString().split( "." ).shift() }Z`;
};

// Since this util is mostly used to convert local dates to UTC ones and pass to server,
//  the mode of parsing `dateValue` is defaulted to be local.
export const convertToUTCTime = ( dateValue, formatString = DefaultTimestampFormat ) => {
  if ( !dateValue ) {
    return "";
  }
  //local-parsing mode then converting to utc object
  const receivedTime = dayjs( dateValue ).utc();
  // Since ISO is a standard format to be passed to server, it stands out as a built-in format.
  return formatString === "iso" ? receivedTime.toISOString() : receivedTime.format( formatString );
};
// Coversion to ISO format can happen under any use-case, hence it's important to
// check the `mode` of conversion. It is defaulted to `local` but can be utc on-demand.
// Truncating the Zulu ( `Z` ) from the end of the string must be avoided if passing to server.
export const toISOFormat = ( datetime, mode = "local", truncateZ = true ) => {
  if ( !validateTimestamp( datetime )) {
    return;
  }
  const parsedDate = ( mode === "utc" ) ? dayjs.utc( datetime ) : dayjs( datetime );
  const parsedISODate = parsedDate.toISOString();
  return truncateZ ? parsedISODate.slice( 0, -1 ) : parsedISODate;
};
export const validateTimestamp = ( dateValue ) => {
  if ( typeof dateValue !== "undefined" ) {
    try {
      return dayjs( dateValue ).isValid();
    } catch ( ex ) {
      return false;
    }
  } else {
    return false;
  }
};
export const sortTimestamps = ( a, b, order = "desc" ) => {
  if ( validateTimestamp( a ) && validateTimestamp( b )) {
    return order === "desc" ? (( dayjs( b ).isAfter( dayjs( a ))) ? 1
      : ( dayjs( a ).isAfter( dayjs( b )) ? -1 : 0 ))
      : (( dayjs( a ).isAfter( dayjs( b ))) ? 1
        : ( dayjs( b ).isAfter( dayjs( a )) ? -1 : 0 ));
  } else {
    return 0;
  }
};
export const toMoment = ( dateValue, type = "obj", formatString = "none" ) => {
  const momentObj = dateValue ? dayjs( dateValue ) : dayjs();
  return type === "epoch" ? momentObj.valueOf() : ( formatString === "none" ? momentObj : momentObj.format( formatString ));
};
export const findDuration = ( from, to, format = "mins", float = true ) => {
  if ( validateTimestamp( from ) && validateTimestamp( to )) {
    switch ( format ) {
    case "ms":
      return dayjs( from ).diff( dayjs( to ), "ms", float );
    case "secs":
      return dayjs( from ).diff( dayjs( to ), "s", float );
    case "mins":
      return dayjs( from ).diff( dayjs( to ), "m", float );
    case "hrs":
      return dayjs( from ).diff( dayjs( to ), "h", float );
    case "month":
      return dayjs( from ).diff( dayjs( to ), "M", float );
    default:
      return dayjs( from ).diff( dayjs( to ), "m", float );
    }
  } else {
    return 0;
  }
};
export const monthsAsOptions = AllMonths.map(( month, index ) =>
  ({ value: dayjs().set( "month", index ).format( "MM" ), label: month }));
export const yearsAsOptions = [
  { value: dayjs().subtract( 1, "year" ).format( "YYYY" ), label: dayjs().subtract( 1, "year" ).format( "YYYY" ) },
  { value: dayjs().format( "YYYY" ), label: dayjs().format( "YYYY" ) }
];
export const operateOnTimestanp = ( dateValue, operationType = "add", value = 0, periodType = "m" ) => {
  if ( validateTimestamp( dateValue )) {
    const dateObj = dayjs( dateValue );
    switch ( operationType ) {
    case "add":
      return dateObj.add( value, periodType );
    case "subtract":
      return dateObj.subtract( value, periodType );
    default:
      return dateObj.add( value, periodType );
    }
  } else {
    return undefined;
  }
};

export const convertToLocalDateTime = ( dateValue ) => {
  return dateValue ? dayjs.utc( dateValue ).local().format( "YYYY-MM-DDTHH:MM" ) : "";
};

export const dateOnlyFormat = ( dateValue ) => {
  if ( validateTimestamp( dateValue )){
    return dayjs( dateValue ).local().format( "DD MMM, YYYY" );
  } else {
    return "Invalid Date";
  }
};