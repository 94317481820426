import React, { Fragment, useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Helmet } from "react-helmet";
import Icon from "../common/Icon";
import clsx from "clsx";
// import { news } from "../media/news/list";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { useRTL } from "../../utils/hooks/useRTL";
import { lngs } from "../../locales/i18n";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";
import { logout } from "../../modules/auth/actionCreators";
import { useRoutes } from "../../utils/hooks/useRoutes";
import { ShowAllSocialMediaIcons } from "../common/socialMedia";
import BannerBG from "../../assets/images/Banner.jpeg";
import ENLogo from "../../assets/images/logo.png";
import ARLogo from "../../assets/images/logo-ar.png";
import styles from "./header.module.scss";
import { aboutUs, adminRole, contactAndFaqs, getTitle, media, paymentGateway, userRole } from "./utils";
import { alertAndHide } from "../../utils/alertUtils";
import { SaveUserAnalytics } from "../home/viewUser/apiService";
import axios from "axios";
import { fetchUserDataById } from "../usermanagment/apiService";
import { updateAuth, updateURL, updateAuthUrl, updatetrackingid } from "../../modules/auth/reducer";
import CryptoJS from 'crypto-js';
import RERAREPORT_EN from '../reports/rera_reports/RERA_REPORT_EN.pdf'
import RERAREPORT_AR from '../reports/rera_reports/RERA_REPORT_AR.pdf'

interface IStateProps {
  userName?: string,
  isAuthorised: boolean,
  fetchingVD: Record<string, boolean | undefined>,
  viewData: Record<string, any[]>,
  fetchVDError: Record<string, any>,
  gid: string,
  rid: string,
  role_id: string,
  tokenact: string,
  email: string
}

interface HeaderProps {
  updateLanguage?: () => void;
}

const Header = ({
  updateLanguage
}: HeaderProps): JSX.Element => {
  const permanentPaths = useRoutes();
  const { role_id, gid, email, tokenact, rid } = useAppSelector(mapStateHook);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [link, setlink] = useState("");
  const isRTL = useRTL();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [groupgid, setgroupgidValue] = useState("");
  const lang = i18n.language?.includes("ar") ? "ar" : "en";
  const isActivePath = React.useCallback((path: string) => {
    return pathname?.startsWith(`/${path}`);
  }, [pathname]);
  const title = React.useMemo(() => getTitle(pathname), [pathname]);
  const Base_Url = `${window.location.origin}/`;
  const Base_UrlHome = `${window.location.origin}/home`;
  const Base_UrlAqari = `${window.location.origin}/aqari`;
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [ip, setIP] = useState("");
  const akariDat = useAppSelector((state) => state.reports.akariDat);
  const objectLength = Object.keys(akariDat).length;
  //console.log("akariDat,,,,,,,,,,,",objectLength);
  const checkPayment = useAppSelector((state) => state.reports.checkpayment);
  const [currentSubscription, setCurrentSubscription] = useState<string>("");
  //console.log("dddddddddd",checkPayment)
  const isExist = objectLength > 0 ? true : false;
  const redirectUrl = (redirct: string) => {
    redirct == "aqari" ? navigate("/aqari") : ""
  }

  const handleClick = async (enlink: string, arlink: string, Lng: string, group_gid: any, isundermaintenance: boolean) => {

    if (isundermaintenance) {
      alertAndHide({ icon: "warning", title: t(`subscriptiontype.undermaintenance`), message: '' });
      return false;
    }
    if (tokenact == null) {
      dispatch(logout(navigate));
    }
    group_gid = 1;
    const redirectLink: string = Lng === "en" ? (enlink || arlink) : (arlink || enlink);
    setlink(redirectLink);
    const logintime = sessionStorage.getItem("loginTime");

    const layload = {
      "user_id": gid !== "" ? gid : 0,
      "deviceid": ip,
      "token": tokenact !== undefined ? tokenact : "",
      "activity": "",
      "activity1": "",
      "activity2": "",
      "activity5": "",
      "activity4": "",
      "usersubscription_type_id": currentSubscription !== "" ? currentSubscription : 0,
      "isuser_have_access": true,
      "click_type": 1,
      "where_clicked": "",
      "user_activity_page_gid": 2,
      "user_activity_page_item_gid": 2,
      "aqari_group_gid": group_gid !== "" ? group_gid : 0
    }
    // const UserAnalytics = await SaveUserAnalytics(layload, tokenact);
    // dispatch(updatetrackingid(UserAnalytics.data[0].usp_save_user_visit))

    if (redirectLink) {
      // dispatch(updateAuth(redirectLink));
      dispatch(updateAuthUrl(redirectLink));
      dispatch(updateURL(logintime));
      // const encryptedItemid = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(UserAnalytics.data[0].usp_save_user_visit));
      // const url = `/aqariviewer?id=` + encryptedItemid;
      const url = `/aqariviewer`;
      window.open(url, '_blank');
      // dispatch(openWindow({ id: Date.now(), url })); 
      const UserAnalytics = await SaveUserAnalytics(layload, tokenact);
      dispatch(updatetrackingid(UserAnalytics.data[0].usp_save_user_visit))
    } else {
      alertAndHide({ icon: "warning", title: t(`subscriptiontype.subscriptionMessageTitle`), message: t(`subscriptiontype.subscriptionMessage`) });
    }


  };

  const fetchuserDataAsync = async (_gid: any, _regId: any, _tokentobesent: any) => {
    const response = await fetchUserDataById(gid, rid, tokenact);
    setCurrentSubscription(response.data.user_subscription_mapping[0].subscription_gid)
  }
  // const SubscriptionUpgradeMessage = () => {
  //   const pay = checkPayment[0].is_subscription_active;
  //   let msg =
  //   gid && checkPayment.length > 0 && pay
  //     ? "Please Upgrade subscription  <a href='/requestsubscription'> <span style='color:blue'>to get access</span></a>"
  //     : gid && checkPayment.length > 0 && !pay
  //     ? "Please Pay  <a href='/requestsubscription'> <span style='color:blue'>to get access</span></a>"
  //     : `If you have registered already, please <a href='auth/login'> <span style='color:blue'>Login and subscribe</span></a>. Else, <a href='/auth/register'><span style='color:blue'>register first and subscribe</span></a>`;

  //   alertAndHide({ icon: "warning", title: "", message: msg });
  // };

  const SubscriptionUpgradeMessage = async () => {
    const isSubscriptionActive = checkPayment[0]?.is_subscription_active || false;
    console.log("Subscription Details:", isSubscriptionActive);
    let message = "";
    if (gid && checkPayment.length > 0) {
      if (isSubscriptionActive) {
        message = `${t("subscriptiontype.Pleaseupgradeyoursubscription")} <a href='/requestsubscription'><span style='color:blue'>${t("subscriptiontype.togetaccess")}</span></a>`;
      } else {
        message = `${t("subscriptiontype.Pleasepay")} <a href='/payment_gateway/PaySubscription'><span style='color:blue'>${t("subscriptiontype.togetaccess")}</span></a>`;
      }
    } else if (gid == "" && checkPayment.length == 0 && !isSubscriptionActive) {
      message = `${t("subscriptiontype.registeredAlready")} <a href='auth/login'><span style='color:blue'>${t("subscriptiontype.logSUBSCRIB")}</span></a>. ${t("subscriptiontype.Else")}, <a href='/auth/register'><span style='color:blue'>${t("subscriptiontype.RSIFRIST")}</span></a>`;
    }
    else {
      message = 'Session Expired';
      dispatch(logout(navigate));
    }
    alertAndHide({ icon: "warning", title: "", message: message });
    const layload = {
      "user_id": gid != "" ? gid : 0,
      "deviceid": ip,
      "token": tokenact != "" ? tokenact : "",
      "activity": "",
      "activity1": "",
      "activity2": "",
      "activity5": "",
      "activity4": "",
      "usersubscription_type_id": currentSubscription != "" ? currentSubscription : 0,
      "isuser_have_access": true,
      "click_type": 1,
      "where_clicked": "",
      "user_activity_page_gid": 2,  // for aqari page page_gid is 2
      "user_activity_page_item_gid": 2,
      "aqari_group_gid": 1
    }
    const UserAnalytics = await SaveUserAnalytics(layload, tokenact)
  };

  const getip = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      const ipAddress = response.data.ip;
      setIP(ipAddress);
    } catch (error) {
      console.error('Error getting IP:', error);
    }
  };
  useEffect(() => {

    // Get the header and its offset position
    const header = document.getElementById("myHeader");
    const sticky = header ? header.offsetTop : 0;
    // Function to handle scroll events
    const handleScroll = () => {
      if (window.pageYOffset > sticky) {
        header?.classList.add("sticky");
      } else {
        header?.classList.remove("sticky");
      }
    };
    getip(),
      fetchuserDataAsync(gid, rid, tokenact)
    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (<header className={styles.header}>
    <Helmet>
      <title>{t("header.project")}</title>
      <meta name="description" content={t("header.description")} />
    </Helmet>

    <div className={clsx(styles.bannerContainer, "video-docker absolute top-0 left-0 w-full h-full border-0", "min-h-max object-center", { "sm:min-h-[30vh]": !isActivePath("account") })}>
      <div className="pic-wrapper">
        <figure className="figure pic-1" />
        <figure className="figure pic-2" />
        <figure className="figure pic-3" />
        <figure className="figure pic-4" />
      </div>

      <div className={styles.logoContainer} >
        <Link to="/home" className={"cursor-pointer"} style={{ zIndex: "20", margin: '0 auto' }}>
          <figure className="min-h-max text-white">
            <img
              src={isRTL ? ARLogo : ENLogo}
              alt={t("header.title")}
              className="h-40 mx-auto"
              loading="lazy"
            />
            {t("header.project")}
          </figure>
        </Link>

        <button
          type="button"
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded shadow mx-2" style={{ zIndex: "20", }}
          onClick={updateLanguage}
        >
          {i18n.language === "ar"
            ? lngs.en.shortName
            : lngs.ar.shortName}
        </button>
      </div>
      <Popover className={"bg-transparent w-full container mx-auto"}>

        {/* <div className="absolute inset-0 z-30 pointer-events-none" aria-hidden="true" />  */}
        <div className="relative z-20">
          <div className={styles.menuContainer}>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className={styles.mobileMenuBtn}>
                <span className="sr-only">Open menu</span>
                <Icon icon="bars" className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className={styles.desktopMenu} id="myHeader">
              <Popover.Group as="nav" className="flex space-s-2 items-center">
                <Link to={permanentPaths.home} className={clsx(styles.menuItem)}>
                  <Icon icon="home" />
                </Link>
                {
                  role_id == "1" ? (
                    <Menu as="div" className="relative inline-block text-start">
                      <div className="">
                        <Menu.Button className={clsx(styles.menuItem, { [styles.active]: isActivePath("media") })}>
                          <span className="sr-only">Media options</span>
                          <div className="flex items-center">
                            <span>{t("menu.adminRole.adminsetting")}</span>
                            <Icon icon="chevron-down"
                              className="ms-2 h-3 w-3"
                              aria-hidden="true"
                            />
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute start-0
                        mt-2 w-56 rounded-md shadow-lg bg-white ring-1
                        ring-black ring-opacity-5 focus:outline-none z-20">
                          <div className="py-1">
                            {adminRole.map(item => (
                              <Menu.Item key={item.id}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={clsx(
                                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                      "px-4 py-2 text-sm space-s-2 flex items-center"
                                    )}
                                  >
                                    <Icon icon={item.icon as IconProp} className="flex-shrink-0 h-6 w-6 text-reraNavyBlue-400" aria-hidden="true" />
                                    <span>{t(`menu.adminRole.${item.id}Title`)}</span>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                  ) : role_id == "0" ? (

                    <Menu as="div" className="relative inline-block text-start">
                      <div className="px-4">
                        <Menu.Button className={clsx(styles.menuItem, { [styles.active]: isActivePath("media") })}>
                          <span className="sr-only">Media options</span>
                          <div className="flex items-center">
                            <span>{t("auth.userSetting")} </span>
                            <Icon icon="chevron-down"
                              className="ms-2 h-3 w-3"
                              aria-hidden="true"
                            />
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute start-0
                        mt-2 w-56 rounded-md shadow-lg bg-white ring-1
                        ring-black ring-opacity-5 focus:outline-none z-20">
                          <div className="py-1">
                            {userRole.map(item => (
                              <Menu.Item key={item.id}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={clsx(
                                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                      "px-4 py-2 text-sm space-s-2 flex items-center"
                                    )}
                                  >
                                    <Icon icon={item.icon as IconProp} className="flex-shrink-0 h-6 w-6 text-reraNavyBlue-400" aria-hidden="true" />
                                    {t(`usermanagement.${item.id}Title`)}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                  ) : ""
                }

                {
                  gid ? (
                    <Link to="/payment_gateway/PaySubscription" className={clsx(styles.menuItem, "hover:bg-reraNavyBlueHover", "px-4")}>
                      {t("payment_gateway.PaySubscriptionTitle")}
                    </Link>
                  ) : ""
                }

                <Link to="/aqari" className={clsx(styles.menuItem, "px-4")}>
                  {t("menu.about.StartExploringTitle")}
                </Link>
                <Link to="/faq" className={clsx(styles.menuItem, "px-4")}>
                  {t("menu.faq")}
                </Link>
                {/* <Link to="/media/news" className={clsx(styles.menuItem, "px-4")}>
                {t("menu.about.news")}
                </Link> */}
                <Link to="/contact-us" className={clsx(styles.menuItem, "px-4")}>
                  {t("menu.contact.title")}
                </Link>
                <Link to={i18n.language === "ar" ? "https://www.rera.gov.bh/" : "https://www.rera.gov.bh/en/"} className={clsx(styles.menuItem, "px-4")} target="_blank">
                  {t("menu.about.aboutReraTitle")}
                </Link>
                <Link to={i18n.language === "ar" ? RERAREPORT_AR : RERAREPORT_EN} className={clsx(styles.menuItem, "px-4")} target="_blank">
                  {t("menu.about.reraReportTitle")}
                </Link>
              </Popover.Group>

              {!gid ? <div className="flex items-center">

                <Link
                  to="auth/login"
                  className=" text-white float-left mt-1  px-2 hover:bg-reraNavyBlueHover"
                >
                  {i18n.language === "ar" ? "تسجيل الدخول" : "Login"}
                </Link>
                <Link
                  to="auth/register"
                  className={clsx("text-white float-left mt-1  px-2 hover:bg-reraNavyBlueHover", lang === "en" ? "border-l-2" : "border-r-2")}

                >
                  {i18n.language === "ar" ? "التسجيل" : "Register"}
                </Link>

              </div>
                :
                <Menu as="div" className="relative inline-block text-start">
                  <div >
                    <span className=" text-white float-left mt-1  pr-2 ">{email}</span>

                    {gid ? (
                      <Link
                        to="/logout"
                        className=" text-white float-left mt-1 border-l-2 pl-2 hover:bg-reraNavyBlueHover"
                      >
                        {t("header.signOut")}
                      </Link>

                    ) : ""}
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute end-0
                     mt-2 w-56 rounded-md shadow-lg bg-white ring-1
                     ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/ViewUserProfile"
                              className={clsx(
                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {email}
                            </Link>
                          )}
                        </Menu.Item>
                        <hr />

                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>}

            </div>
          </div>
        </div>


        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-40 top-0  p-4 transition transform origin-top-right"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray max-w-screen-xl mx-auto ">
              <div className="pt-5 pb-6 px-5 sm:pb-8" style={{ 'minWidth': '320px' }}>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="-mr-2 mb-3">
                    <Popover.Button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center
                                  text-slate-400 hover:text-slate-500 hover:bg-slate-100 focus:outline-none focus:ring-2
                                  focus:ring-inset focus:ring-reraNavyBlue"
                    >
                      <span className="sr-only">Close menu</span>
                      <Icon
                        icon="times"
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
                <div className="ml-3 mb-5">
                  <div className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}>
                    <a href={Base_UrlHome}
                      className="-m-3 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-1"
                      rel="noreferrer"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                        <Icon
                          icon={"home" as IconProp}
                          className="h-4 w-4"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-8 text-base font-medium text-gray-900">
                        {t("menu.home")}
                      </div>
                    </a>
                  </div>
                </div>

                {/* by  admin setting section for user and admin */}
                {
                  role_id == "1" ? (
                    <div className="mb-4">
                      <div
                        className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}
                        //  onClick={() => setSubMenuOpen(true)}
                        //  onMouseLeave={() => setSubMenuOpen(false)}
                        onClick={() => {
                          setSubMenuOpen(!isSubMenuOpen);
                        }}
                      >
                        <div>
                          <a
                            href="javascript:void(0)"
                            className="-m-1 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-1"
                            rel="noreferrer"
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                              <Icon icon={"fa-users-gear" as IconProp} className="h-4 w-4" aria-hidden="true" />
                            </div>
                            <div className="ml-8 text-base font-medium text-gray-900">
                              {t("menu.adminRole.adminsetting")}  <Icon icon="chevron-down" />
                            </div>
                          </a>

                          {isSubMenuOpen && (
                            <div className=" pl-4 absolute bg-white border rounded shadow-md w-4/5">
                              {adminRole.map((item) => (
                                <Popover.Button
                                  as={Link}
                                  key={item.id}
                                  to={item.href}
                                  className="-m-0 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-2"
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                                    <Icon
                                      icon={item.icon as IconProp}
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-4 text-base font-medium text-gray-900">
                                    {t(`menu.adminRole.${item.id}Title`)}
                                  </div>
                                </Popover.Button>
                              ))}

                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : role_id == "0" ? (
                    <div className="mb-4">
                      <div
                        className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}
                        //  onClick={() => setSubMenuOpen(true)}
                        //  onMouseLeave={() => setSubMenuOpen(false)}
                        onClick={() => {
                          setSubMenuOpen(!isSubMenuOpen);
                        }}
                      >
                        <div>
                          <a
                            href="javascript:void(0)"
                            className="-m-1 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-1"
                            rel="noreferrer"
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                              <Icon icon={"fa-users-gear" as IconProp} className="h-4 w-4" aria-hidden="true" />
                            </div>
                            <div className="ml-8 text-base font-medium text-gray-900">

                              {t("auth.userSetting")} <Icon icon="chevron-down" />
                            </div>
                          </a>
                          {isSubMenuOpen && (
                            <div className=" pl-4 absolute bg-white border rounded shadow-md w-4/5">
                              {userRole.map((item) => (
                                <Popover.Button
                                  as={Link}
                                  key={item.id}
                                  to={item.href}
                                  className="-m-0 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-2"
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                                    <Icon
                                      icon={item.icon as IconProp}
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-4 text-base font-medium text-gray-900">
                                    {t(`usermanagement.${item.id}Title`)}
                                  </div>
                                </Popover.Button>
                              ))}

                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : ""
                }
                {/* by admin setting section for user and admin end */}

                {/* payment start*/}
                {
                  role_id == "1" ? (
                    <div className="mt-0  ">
                      <nav>
                        <div className="ml-0">
                          <div className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}>
                            <Popover.Button
                              as={Link}
                              key={"btnpayment"}
                              to={"/payment_gateway/PaySubscription"}
                              className="-m-0 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-2"
                            >
                              <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                                <Icon
                                  icon={"credit-card" as IconProp}
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-gray-900">
                                {t("payment_gateway.PaySubscriptionTitle")}
                              </div>
                            </Popover.Button>
                          </div>
                        </div>

                      </nav>
                    </div>
                  ) : role_id == "0" ? (
                    <div className="mt-0  ">
                      <nav>
                        <div className="ml-0">
                          <div className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}>
                            <Popover.Button
                              as={Link}
                              key={"btnpayment"}
                              to={"/payment_gateway/PaySubscription"}
                              className="-m-0 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-2"
                            >
                              <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                                <Icon
                                  icon={"credit-card" as IconProp}
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-gray-900">
                                {t("payment_gateway.PaySubscriptionTitle")}
                              </div>
                            </Popover.Button>
                          </div>
                        </div>

                      </nav>
                    </div>
                  ) : ""

                }
                {/* pement section end*/}

                <div className="mt-0  ">
                  <nav>
                    <div className="grid gap-0 lg:grid-cols-1 sm:gap-y-3  mt-4 mb-6 sm:gap-10">
                      {aboutUs.map((item) => (
                        <Popover.Button
                          as={Link}
                          key={item.id}
                          to={item.href}
                          className="-m-1 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-2"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                            <Icon
                              icon={item.icon as IconProp}
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {t(`menu.about.${item.id}Title`)}
                          </div>
                        </Popover.Button>
                      ))}
                    </div>
                    <div className="ml-3">

                      <div className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}>
                        <a
                          href={i18n.language === "ar" ? "https://www.rera.gov.bh/" : "https://www.rera.gov.bh/en/"}
                          className="-m-3 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                            <Icon
                              icon={"fingerprint" as IconProp}
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-8 text-base font-medium text-gray-900">
                            {t("menu.about.aboutReraTitle")}
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="ml-3">

                      <div className={clsx("grid gap-x-4 gap-y-7", { "arbicmenuchangeclass": isRTL }, { "grid-cols-1": !isRTL })}>
                        <a
                          href={i18n.language === "ar" ? RERAREPORT_AR : RERAREPORT_EN}
                          className="-m-3 flex items-center p-1 rounded-lg hover:bg-gray-100 space-s-1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-md bg-reraNavyBlue text-white sm:h-8 sm:w-8">
                            <Icon
                              icon={"fingerprint" as IconProp}
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-8 text-base font-medium text-gray-900">
                            {t("menu.about.reraReportTitle")}
                          </div>
                        </a>
                      </div>
                    </div>
                  </nav>
                </div>

              </div>
              <div className="py-6 px-5">
                {!gid ? (
                  <Popover.Button
                    as={Link}
                    to="/auth/login"
                    className="w-full flex items-center justify-center px-4 py-2
              border border-transparent rounded-md shadow-sm text-base font-medium
              text-white bg-reraNavyBlue hover:bg-reraNavyBlueHover"
                  >

                    Sign In
                  </Popover.Button>
                ) : (
                  <Popover.Button
                    as={Link}
                    to="/logout"
                    className="w-full flex items-center justify-center px-4 py-2
              border border-transparent rounded-md shadow-sm text-base font-medium
              text-white bg-reraNavyBlue hover:bg-reraNavyBlueHover space-s-2"
                  >
                    <Icon icon="user" /> <span> Log out</span>
                  </Popover.Button>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

    </div>
    {
      window.location.href === Base_UrlAqari ? (
        <div className="my-auto mx-auto w-full text-center text-white -mt-1">
          <h1 className="text-5xl font-bold">
            {" "}
            {t("header.AQARIMapViewer")}
          </h1>
        </div>
      ) : (
        <div className="my-auto mx-auto w-full text-center text-white -mt-1">
          <h1 className="text-5xl font-bold">
            {" "}
            {t("header.AQARIMapViewer")}
          </h1>
        </div>
      )
    }

    {window.location.href === Base_Url ? (
      <div className="mt-auto w-full -mt-1">
        <div className="w-full p-4 text-center bg-overlay shadow sm:p-8 dark:border-gray-700">
          <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <h5 className="mb-2 text-3xl font-bold text-white dark:text-white">
              <button
                onClick={(e) => redirectUrl("aqari")}
                className="explore-btn"
              >
                <span className="animate-character">
                  {t("header.startExploring")}
                </span>
              </button>
            </h5>
          </div>
        </div>
      </div>
    ) : window.location.href === Base_UrlHome ? (
      <div className="mt-auto w-full -mt-1">
        <div className={clsx("w-full p-4  bg-overlay shadow sm:p-8 dark:border-gray-700", "text-left")}>
          <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">

            <h5 className="mb-2 text-3xl font-bold text-white dark:text-white">

              <button
                onClick={(e) => redirectUrl("aqari")}
                className="explore-btn"
              >
                <span className="animate-character">
                  {t("header.startExploring")}
                </span>
              </button>

            </h5>
          </div>
        </div>
      </div>
    ) : window.location.href === Base_UrlAqari ? (
      <div className="mt-auto w-full -mt-1">
        <div className={clsx("w-full p-4  bg-overlay shadow sm:p-8 dark:border-gray-700", "text-left")}>
          <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <h5 className="mb-2 text-3xl font-bold text-white dark:text-white">

              {isExist ? (
                <button
                  onClick={(e) => handleClick(akariDat?.resource_link_en, akariDat?.resource_link_ar, lang, groupgid, akariDat?.isundermaintenance)}
                  className="explore-btn"
                >
                  <span className="animate-character">
                    {t("header.InerstartExploring")}
                  </span>
                </button>
              ) : (

                <button className="explore-btn" onClick={SubscriptionUpgradeMessage}>
                  <span className="animate-character">
                    {t("header.InerstartExploring")}
                  </span>
                </button>
              )}

            </h5>
          </div>
        </div>
      </div>
    ) : null}
  </header>
  );
};

const mapStateHook = (state: RootState): IStateProps => {
  return {
    email: state.auth.email,
    isAuthorised: state.auth.token ? true : false,
    fetchingVD: state.reports.fetchingVD,
    viewData: state.reports.viewData,
    fetchVDError: state.reports.fetchVDError,
    gid: state.auth.gid,
    rid: state.auth.rid,
    role_id: state.auth.role_id,
    tokenact: state.auth.tokenact
  };
};


export default React.memo(Header);
