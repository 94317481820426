import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { bahrainArial, butterflySculpture,
  vectorMap, directorSigningDocuments } from "../../assets/images/staticImages";
import { useFetchFile } from "../../utils/hooks/useFetchFile";
import Icon from "../common/Icon";
import styles from "./about.module.scss";

const path = "/content/about/about.json";
const About = () => {
  const { t, i18n } = useTranslation(); //eslint-disable-line
  const lang = i18n.language?.includes( "ar" ) ? "ar" : "en";
  const sections = [
    // { title: "vision", image: bahrainArial },
    // { title: "mission", image: butterflySculpture },
    { title: "approach", image: vectorMap }
  ];
  const {
    isLoading, isError, data, error, refetch
  } = useFetchFile( path );

  return <>
    { isLoading
      ? <div className="flex flex-col my-12 justify-center items-center">
        <Icon icon="spinner" spin={true} size="4x" className="text-reraNavyBlue" />
      </div>
      : ( isError ? <div className="flex flex-col justify-center items-center p-4 h-36 bg-slate-300 border border-dashed border-slate-400 rounded-md">
        <p>{JSON.stringify( error )}</p>
        <button className="bg-slate-600 hover:bg-slate-800 text-white font-bold py-2 px-4 rounded-md" onClick={() => refetch( path )}>
      Reload
        </button>
      </div> : <div className={styles.about}>
        <div className="space-y-4">
          <h2 className="text-3xl text-red-600 uppercase font-bold">{t( "about.title" )}</h2>
          <div dangerouslySetInnerHTML={{ __html: data?.about?.[lang] }} />
        </div>
        {sections.map(( section, sIdx ) => <div className="relative rounded-md group" key={sIdx}>
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-center object-cover rounded-md"
              src={section.image}
              alt={section.title}
            />
            <div
              className={clsx(
                "absolute inset-4 bg-opacity-50 mix-blend-multiply rounded-md",
                "group-hover:animate-all duration-500 print:hidden"
              )}
              aria-hidden="true" />
          </div>
          <div className="relative w-full mx-auto py-12 px-8 flex flex-col items-center print:text-white h-96">
            <h1 className="text-3xl font-extrabold tracking-tight text-white">{t( `about.${section.title}` )}</h1>
            {/* <div className={styles.sectionText} dangerouslySetInnerHTML={{ __html: data?.[section.title]?.[lang] }} /> */}
          </div>
        </div> )}
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 relative my-4 p-4 rounded-md shadow-md">
          <div className="relative">
            <img
              className="w-full max-h-80 sm:h-full sm:max-h-full object-center object-cover rounded-md"
              src={directorSigningDocuments}
              alt={"ceo image"}
            />
            <div
              className={clsx(
                "absolute inset-4 bg-opacity-50 mix-blend-multiply rounded-md"
              )}
              aria-hidden="true" />
          </div>
          <div className="relative p-4">
            <h3 className="text-2xl font-bold text-reraRed">{t( "about.chairmanMessage" )}</h3>
            <div dangerouslySetInnerHTML={{ __html: data?.chairmanMessage?.[lang] }} />
          </div>
        </div> */}
      </div> )}
  </>;
};

export default About;