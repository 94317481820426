import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "../common/actions";

export interface ReportState {
    fetchingVD: Record<string, boolean>;
    viewData: Record<string, any>;
    fetchVDError: Record<string, any>;
    akariDat:Record<string, any>;
    checkpayment:Record<string, any>;
}

const initialState: ReportState = {
  fetchingVD: {},
  viewData: {},
  fetchVDError: {},
  akariDat:{},
  checkpayment:{}
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateReportsSlice( state, action ) {
      Object.assign( state, action.payload );
    },
    updateFetchingVD( state, action ) {
      Object.assign( state.fetchingVD, action.payload );
    },
    updateViewData( state, action ) {
      Object.assign( state.viewData, action.payload );
    },
    updateVDError( state, action ) {
      Object.assign( state.fetchVDError, action.payload );
    },
    resetReportsSlice( state ){
      Object.assign( state, initialState );
    },
    updateAkariDat(state, action) {
      state.akariDat = action.payload;
    },
    checkPaymentStore(state, action){
      state.checkpayment = action.payload;
    }
  },
  extraReducers: ( builder ) => {
    builder.addMatcher(
      action => logoutAction.match( action ),
      ( state ) => {
        state = initialState;
        return state;
      }
    );
  }
});

export const { updateReportsSlice, updateFetchingVD, updateViewData, updateVDError, resetReportsSlice, updateAkariDat,checkPaymentStore } = reportsSlice.actions;

export default reportsSlice.reducer;