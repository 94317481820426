import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslations from "./en.json";
import arTranslations from "./ar.json";

export type LangKeys = "en" | "ar";

export const lngs = {
  en: { nativeName: "English", shortName: "En" },
  ar: { nativeName: "عربى", shortName: "عربى" }
} as const;

i18n
  .use( LanguageDetector )
  // pass the i18n instance to react-i18next.
  .use( initReactI18next )
  // init i18next
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: ( enTranslations as {translation: Record<string, any>}).translation
      },
      ar: {
        translation: ( arTranslations as {translation: Record<string, any>}).translation
      }
    }
  });

export default i18n;