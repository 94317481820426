import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
  createApi
} from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Auth } from "aws-amplify";
import { RootState } from "../";
import { updateAuthReducer } from "../../modules/auth/actionCreators";
import config from "../../config.json";

const TAGS = [ "AccountUser", "Users", "Roles", "Groups", "Reports", "Requests", "Presigned", "Views", "Items", "Search", "UsageStats" ] as const;

// initialize an empty api service that we'll inject endpoints into later as needed

const query = fetchBaseQuery({
  baseUrl: config.API_gateway,
  prepareHeaders: ( headers, { getState, extra }) => {
    const { auth: { token } } = getState() as RootState;
    const { avoidAuthHeader = false } = extra as { avoidAuthHeader?: boolean};
    if ( token ) {
      headers.set( "authorization", token );
    }
    // for making public calls even after logging in
    if ( token && avoidAuthHeader ){
      headers.delete( "authorization" );
    }

    return headers;
  }
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = retry( async ( args, api, extraOptions ) => {
  const apiUrl = ( api.type === "query" ? ( typeof args === "string" ? args : args?.url || "" ) : ( args?.url || "" )) as string;
  const publicExtraOptions = ( apiUrl.startsWith( "public/" ) || apiUrl.includes( "-public/" ) ||
  extraOptions === "avoidAuthHeader" ) ? { avoidAuthHeader: true } : {};
  let result = await query( args, { ...api, extra: publicExtraOptions }, extraOptions );
  if ( result.error?.status === 401 || result.error?.status === 403 ) {
    const configuredToken = result.meta?.request.headers.get( "Authorization" ) || "";
    const { exp } = configuredToken && jwtDecode<JwtPayload>( configuredToken ) || {};
    if ( exp && dayjs().isAfter( dayjs.unix( exp ))) {
      try {
        const cognitoUserSession: any = await Auth.currentSession();
        api.dispatch( updateAuthReducer({ token: cognitoUserSession?.idToken?.jwtToken }));
        result = await query( args, { ...api, extra: publicExtraOptions }, extraOptions );
      } catch ( error: any ){
        // eslint-disable-next-line no-console
        console.error( error );
      }
    }
  }
  return result;
}, { maxRetries: 1 });

export const reraEmptySplitApi = createApi({
  baseQuery,
  reducerPath: "reraApis",
  tagTypes: TAGS,
  refetchOnMountOrArgChange: 3 * 60 * 1000,
  endpoints: () => ({})
});

export default reraEmptySplitApi;