import {
  ISweetAlertIcon, IShowLoading, IServiceAlert,
  IShowMessage, IAlertAndHide, IConfirmation, IShowToast,
  IConfirmation1
} from "./alertUtils.types";
import Swal, { SweetAlertPosition, SweetAlertOptions } from "sweetalert2";

import "../assets/css/Alerts.scss";

export const closeSwal = () => {
  return Swal.close();
};

export const showLoading = ({
  title = "",
  backdrop = true
}: IShowLoading ) => {
  return Swal.fire({
    title,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    backdrop,
    didOpen: () => {
      Swal.showLoading();
    }
  });
};

export const showToast = ({
  type = "info" as ISweetAlertIcon,
  title = "",
  className = "",
  confirmButtonText = "",
  cancelButtonText = "close",
  confirmAction,
  position = "top-end" as SweetAlertPosition,
  timer = 8000
}: IShowToast ) => {
  Swal.fire({
    icon: type !== "loading" ? type : undefined,
    title,
    toast: true,
    position,
    showConfirmButton: confirmButtonText.length > 0,
    showCancelButton: ( cancelButtonText.length > 0 && type !== "loading" ),
    timer: type === "loading" ? 60000 : timer,
    timerProgressBar: timer > 5000,
    confirmButtonText,
    cancelButtonText,
    didOpen: () => {
      if ( type === "loading" ) {
        Swal.showLoading();
      } else {
        Swal.hideLoading();
      }
    },
    customClass: {
      container: className
    }
  }).then(( result ) => {
    if ( result.value ) {
      if ( typeof confirmAction === "function" ) {
        confirmAction();
      }
    } else {
      Swal.close();
    }
  });
};

export const alertAndHide = ({
  icon,
  title = "Request failed",
  message,
  onCloseCallback,
  hasCancel = false,
  confirmButtonText = "OK",
  allowEscapeKey = true,
  allowOutsideClick = true
}: IAlertAndHide ) => {
  return Swal.fire({
    title,
    icon,
    html: `<span>${message ?? ""}</span>`,
    allowEscapeKey,
    allowOutsideClick,
    backdrop: allowOutsideClick,
    reverseButtons: true,
    confirmButtonText,
    showCancelButton: hasCancel,
    didOpen: () => {
      Swal.hideLoading();
    },
    didClose: () => {
      if ( onCloseCallback && typeof onCloseCallback === "function" ) {
        onCloseCallback();
      }
    }
  });
};

export const accessAlert = ({ service }: IServiceAlert ) => {
  return Swal.fire({
    title: "Access Denied",
    icon: "warning",
    text: `You do not have enough permissions to perform this action. Please contact your system administrator regarding ${service || "service"} permission(s)`
  });
};

export const showMessage = ({
  title = "Message",
  text = "",
  className = "",
  allowCopy = false
}: IShowMessage ) => {
  return Swal.fire({
    title,
    html: allowCopy ? `${text} <span class="show" title="click to copy"
    onclick="javascript: { let el = document.createElement('textarea');el.value = '${text.replace( /'/g, "&quot;" ).replace( /"/g, "&quot;" )}';
    document.body.appendChild(el);el.select();
    document.execCommand('copy');document.body.removeChild(el);document.querySelector('#showmessage-message-copied').innerHTML = 'Copied';
    setTimeout(function fn(){document.querySelector('#showmessage-message-copied').innerHTML = ''}, 1000)}">
                        <i class="fa fas fa-copy text-default text-bolder"></i>
                    </span><p id="showmessage-message-copied" class="badge mt-1 bg-success text-white pull-right"></p>`
      : `${text}`,
    confirmButtonText: "Close",
    customClass: {
      container: `custom-message ${className}`
    }
  });
};

export const Confirmation = async ({
  title = "Confirmation",
  icon,
  text,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  showLoader = true,
  onConfirm,
  onCancel,
  onClose
}: IConfirmation ) => {
  return Swal.fire({
    title,
    html: text,
    icon,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText,
    cancelButtonText,
    showLoaderOnConfirm: true,
    preConfirm: () => null,
    customClass: {
      container: "custom-message",
      popup: "text-bold",
      title: "text-bolder text-espRed",
      confirmButton: "bg-espRed confirm btn-danger",
      cancelButton: "bg-gray"
    },
    didClose: () => {
      if ( typeof onClose === "function" ) {
        onClose();
      }
    }
  }).then(( result ) => {
    if ( result.isConfirmed ) {
      if ( typeof onConfirm === "function" ) {
        if ( showLoader ) {
          showLoading({});
        }
        onConfirm();
      }
    } else {
      if ( typeof onCancel === "function" ) {
        onCancel();
      }
    }
  });
};

export const ConfirmationWithThreeButtons = async ({
  title = "Confirmation",
  icon,
  text,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  thirdButtonText = "Third Option",
  showLoader = true,
  onConfirm,
  onCancel,
  onThirdButtonClick,
  onClose
}: IConfirmation1) => {
  return Swal.fire({
    title,
    html: text,
    icon,
    showCancelButton: true,
    showConfirmButton: true,
    showDenyButton: true,
    reverseButtons: true,
    confirmButtonText,
    cancelButtonText,
    denyButtonText: thirdButtonText,
    showLoaderOnConfirm: true,
    preConfirm: () => null,
    didClose: () => {
      if (typeof onClose === "function") {
        onClose();
      }
    }
  }).then((result) => {
    if (result.isConfirmed) {
      if (typeof onConfirm === "function") {
        if (showLoader) {
          showLoading({});
        }
        onConfirm();
      }
    } 
    else if (result.dismiss === Swal.DismissReason.cancel) {
      if (typeof onCancel === "function") {
        onCancel();
      }
    }
    else   if (typeof onThirdButtonClick === "function") {
      if (showLoader) {
        showLoading({});
      }
      onThirdButtonClick();
    }
  });
};

export const customSwal = ( config: SweetAlertOptions ) => {
  return Swal.fire({
    ...config
  });
};