export enum authActions {
    RESEND_INITIAL = "RESEND_INITIAL",
    RESEND_PROCESSING = "RESEND_PROCESSING",
    RESEND_SUCCESS = "RESEND_SUCCESS",
    RESEND_FAILURE = "RESEND_FAILURE",
    CONFIRM_SIGN_IN_INITIAL = "CONFIRM_SIGN_IN_INITIAL",
    CONFIRM_SIGN_IN_PROCESSING = "CONFIRM_SIGN_IN_PROCESSING",
    CONFIRM_SIGN_IN_SUCCESS = "CONFIRM_SIGN_IN_SUCCESS",
    CONFIRM_SIGN_IN_FAILURE = "CONFIRM_SIGN_IN_FAILURE",
    CONFIRM_SIGN_UP_INITIAL = "CONFIRM_SIGN_UP_INITIAL",
    CONFIRM_SIGN_UP_PROCESSING = "CONFIRM_SIGN_UP_PROCESSING",
    CONFIRM_SIGN_UP_SUCCESS = "CONFIRM_SIGN_UP_SUCCESS",
    CONFIRM_SIGN_UP_FAILURE = "CONFIRM_SIGN_UP_FAILURE",
    FORGOT_PWD_INITIAL = "FORGOT_PWD_INITIAL",
    FORGOT_PWD_PROCESSING = "FORGOT_PWD_PROCESSING",
    FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS",
    FORGOT_PWD_FAILURE = "FORGOT_PWD_FAILURE",
    FORGOT_PWD_SUBMIT_INITIAL = "FORGOT_PWD_SUBMIT_INITIAL",
    FORGOT_PWD_SUBMIT_PROCESSING = "FORGOT_PWD_SUBMIT_PROCESSING",
    FORGOT_PWD_SUBMIT_SUCCESS = "FORGOT_PWD_SUBMIT_SUCCESS",
    FORGOT_PWD_SUBMIT_FAILURE = "FORGOT_PWD_SUBMIT_FAILURE",
    FORGOT_PWD_RESEND_CODE_INITIAL = "FORGOT_PWD_RESEND_CODE_INITIAL",
    FORGOT_PWD_RESEND_CODE_PROCESSING = "FORGOT_PWD_RESEND_CODE_PROCESSING",
    FORGOT_PWD_RESEND_CODE_SUCCESS = "FORGOT_PWD_RESEND_CODE_SUCCESS",
    FORGOT_PWD_RESEND_CODE_FAILURE = "FORGOT_PWD_RESEND_CODE_FAILURE",
    LOGIN_INITIAL = "LOGIN_INITIAL",
    LOGIN_PROCESSING = "LOGIN_PROCESSING",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILURE = "LOGIN_FAILURE",
    SIGN_UP_INITIAL = "SIGN_UP_INITIAL",
    SIGN_UP_PROCESSING = "SIGN_UP_PROCESSING",
    SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS",
    SIGN_UP_FAILURE = "SIGN_UP_FAILURE",
    SET_SESSION = "SET_SESSION"
  }