interface IStateProps {
  userName?: string,
  isAuthorised: boolean,
  fetchingVD: Record<string, boolean | undefined>;
  viewData: Record<string, any[]>;
  fetchVDError: Record<string, any>;
  gid: string;
  rid: string;
  token:string;
  role_id:string;
  email:string;
  trackingid:string
}

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import { createStateSyncMiddleware } from "redux-state-sync";
import storage from "redux-persist/lib/storage";
// import media from "./reducers/media";
import reports from "../modules/reports/reducer";
import reraApi from "./apis";
import auth from "../modules/auth/reducer";
import account from "../modules/account/reducer";
import globalConfig from "../modules/globalConfig/reducer";
// import { errorLoggingMiddleware } from "./errorLoggingMiddleware";
import { updateAuth } from "../modules/auth/reducer";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./hooks";
import { SaveUserAnalytics, SessionLogout } from "../components/home/viewUser/apiService";
import sessionStorage from "redux-persist/es/storage/session";
import CryptoJS from 'crypto-js';
import { logoutAction } from "../modules/common/actions";

const persistConfig = {
  key: "rera",
  version: 1,
  storage,
  blacklist: [ reraApi.reducerPath, "reports" ]
};

const rootReducer = combineReducers({
  auth,
  account,
  globalConfig,
  reports,
  [reraApi.reducerPath]: reraApi.reducer
});

const persistedReducer = persistReducer( persistConfig, rootReducer );
const middlewaresList = [
  // errorLoggingMiddleware,
  createStateSyncMiddleware({
    // Following actions won't be triggered in other tabs
    blacklist: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
  }),
  reraApi.middleware
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: ( getDefaultMiddleware ) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
      }
    }).concat( middlewaresList )
});

const mapStateHook = (state: RootState): IStateProps => {
  return {
    userName: state.account.user?.UserId || "",
    isAuthorised: state.auth.token ? true : false,
    fetchingVD: state.reports.fetchingVD,
    viewData: state.reports.viewData,
    fetchVDError: state.reports.fetchVDError,
    gid: state.auth.gid,
    rid: state.auth.rid,
    role_id:state.auth.role_id,
    token:state.auth.tokenact,
    email:state.auth.email,
    trackingid:state.auth.trackingid
  };
}

// window.addEventListener('unload', async (event) => {
//   debugger;
//   console.log("current url of paymentgatway.............",window.location.href)
//   // if(!(window.location.href.includes('payment_gateway'))){
//     // const {
//     //   gid, rid,token,email
//     // } = useAppSelector(mapStateHook);
   
//   //  SessionLogout({
//   //       "username": email,
//   //       "password": "",
//   //       "token": token
//   //     }, token||"");

//   store.dispatch(updateAuth({
//     tokenact: null,
//     refreshToken: null,
//     sessionActive: false,
//     loginStatus: false,
//     gid: null,
//     rid: null,
//     role_id: null,
//     username: null,
//     email: null
//   }));
 
//    const {
//       gid, rid,token,email
//     } = useAppSelector(mapStateHook);
//  SessionLogout({
//         "username": email,
//         "password": "",
//         "token": token
//       }, token||"");
//       store.dispatch( reraApi.util.resetApiState()); 
// });
window.addEventListener('beforeunload', async (event) => {
  debugger;
  // event.preventDefault();
  const { tokenact, email, gid } = store.getState().auth; // Access Redux store directly
  var trackingID = await sessionStorage.getItem("trackid");
  
  // Ensure that trackingID is retrieved properly
 

  if( window.location.href.includes("aqariviewer")==true) {
    // var trackingItemID = window.location.href.substring(window.location.href.indexOf("=") + 1);   
    // const decryptedItemid = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(trackingItemID));
    const layload = {
      "user_id": gid !== "" ? gid : 0,
      "deviceid": "",
      "token": tokenact !== undefined ? tokenact : "",
      "activity": "",
      "activity1": "",
      "activity2": "",
      "activity5": "",
      "activity4": "",
      "usersubscription_type_id": 0,
      "isuser_have_access": true,
      "click_type": trackingID,
      "where_clicked": "tabclose",
      "user_activity_page_gid": 2,
      "user_activity_page_item_gid": 2,
      "aqari_group_gid" : 0
    }
    const UserAnalytics = await SaveUserAnalytics(layload, tokenact);
  }

  else if( window.location.href.includes("payment_gateway")==false &&  window.location.href.includes("aqariviewer")==false && window.location.href.includes("auth/login")==false){
    
    store.dispatch(logoutAction());
    await SessionLogout({
       "username": email,
       "password": "",
       "token": tokenact
     }, tokenact);

   store.dispatch(updateAuth({
     tokenact: null,
     refreshToken: null,
     sessionActive: false,
     loginStatus: false,
     gid: null,
     rid: null,
     role_id: null,
     username: null,
     email: null
   }));
 }
   store.dispatch(reraApi.util.resetApiState());
});

window.addEventListener('load', async (event) => {
  debugger;
  
  const { tokenact, email, gid } = store.getState().auth; // Access Redux store directly
  var trackingID = await sessionStorage.getItem("trackid");

  // Ensure that trackingID is retrieved properly
  if( window.location.href.includes("aqariviewer")==true) {
    // var trackingItemID = window.location.href.substring(window.location.href.indexOf("=") + 1);
    // const decryptedItemid = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(trackingItemID));
  const layload = {
    "user_id": gid !== "" ? gid : 0,
    "deviceid": "",
    "token": tokenact !== undefined ? tokenact : "",
    "activity": "",
    "activity1": "",
    "activity2": "",
    "activity5": "",
    "activity4": "",
    "usersubscription_type_id": 0,
    "isuser_have_access": true,
    "click_type": trackingID,
    "where_clicked": "tabrefresh",
    "user_activity_page_gid": 2,
    "user_activity_page_item_gid": 2,
    "aqari_group_gid" : 0
  }
  
  const UserAnalytics = await SaveUserAnalytics(layload, tokenact);  
}

store.dispatch(reraApi.util.resetApiState());
  
});

window.addEventListener('unload',  (event) => {
  debugger;
  console.log("Done");
   new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
});
export const persistor = persistStore( store );
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


