import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "../common/actions";
import { authActions } from "./actions";

export interface AuthState {
  [x: string]: any;
  email: string;
  role_id: any;
  rid: any;
  gid: string;
  token?: string;
  userInfo?: unknown;
  verifyingCode?: boolean;
  username?: string;
  registrationResendCodeStatus?: string,
  confirmSignInStatus?: string,
  confirmSignUpStatus?: string,
  forgotPwdStatus?: string,
  forgotPwdSubmitStatus?: string,
  forgotPwdResendCodeStatus?: string,
  loginStatus?: string,
  signUpStatus?: string,
  loginErrorMsg?: string,
  registrationErrorMsg?: string,
  forgotPwdResendCodeErrorMsg?: string,
  forgotPwdSubmitErrorMsg?: string,
  forgotPwdErrorMsg?: string,
  verifyTotpTokenErrorMsg?: string,
  confirmTOTPForSignIn?: string,
  resendSignUpErrorMsg?: string,
  confirmSignUpErrorMsg?: string,
  destinationUsername?: string,
  destinationEmail?: string,
  url_aqari: any,
  trackingid:any
 
}

const initialState: AuthState = {
  token: undefined,
  verifyingCode: false,
  userInfo: {},
  registrationResendCodeStatus: authActions?.RESEND_INITIAL,
  confirmSignInStatus: authActions?.CONFIRM_SIGN_IN_INITIAL,
  confirmSignUpStatus: authActions?.CONFIRM_SIGN_UP_INITIAL,
  forgotPwdStatus: authActions?.FORGOT_PWD_INITIAL,
  forgotPwdSubmitStatus: authActions?.FORGOT_PWD_SUBMIT_INITIAL,
  forgotPwdResendCodeStatus: authActions?.FORGOT_PWD_RESEND_CODE_INITIAL,
  loginStatus: authActions?.LOGIN_INITIAL,
  signUpStatus: authActions?.SIGN_UP_INITIAL,
  gid: "",
  rid: undefined,
  role_id: "",
  email: "",
  url_aqari: '',
  trackingid:''
 
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // updateAuth( state, action ) {
    //   Object.assign( state, action.payload );
    //   state.url_aqari = action.payload;
    //  //
    // },
    updateAuth( state, action ) {
      Object.assign( state, action.payload );
      // state.url_aqari = action.payload;
     //
    },
    updateAuthUrl( state, action ) {
      Object.assign( state, action.payload );
       state.url_aqari = action.payload;
     //
    },
    updateURL(state, action) {
      Object.assign( state, action.payload );
      state.logintime = action.payload;
    },
   
    updatetrackingid(state, action) {
      Object.assign( state, action.payload );
      state.trackingid = action.payload;
    },

  },
  
  extraReducers: ( builder ) => {
    builder.addMatcher(
      action => logoutAction.match( action ),
      ( state ) => {
        state = initialState;
        return state;
      }
    );
  }
}
);

// export const { updateAuth ,updateURL,updatetrackingid } = authSlice.actions;
export const { updateAuth ,updateURL,updatetrackingid,updateAuthUrl } = authSlice.actions;
export default authSlice.reducer;