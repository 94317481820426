import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.scss";
import App from "./App";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./locales/i18n";
import { PermissionContextProvider } from "./utils/hooks/Permissions/PermissionContext";
// import { DataProvider } from './utils/contextaqariviewer';

const root = ReactDOM.createRoot(
  document.getElementById( "root" ) as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <PermissionContextProvider>
          <App />
        </PermissionContextProvider>
      </PersistGate>
    </Provider >
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
