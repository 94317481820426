import axios, { AxiosResponse } from 'axios';
import config from "../../../config.json";
const API_BASE_URL = config.GLOBAL_SETTINGS.Host;
 

export const fetchSubscriptionData = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageSubscriptionRequest`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSubscriptionData1 = async (id:string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageSubscriptionRequest/`+id);
    return response;
  } catch (error) {
    throw error;
  }
};
// export const fetchUserData = async (id:string,token:string): Promise<AxiosResponse> => {
//   try {

// 	const response = await axios.get(`${API_BASE_URL}/ManageUser/GetUserListWithPlanDL?id=`+id);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchUserData = async (id: number, tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(     
     `${API_BASE_URL}/ManageUser/GetUserListWithPlanDL?id=`+id,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
    
    throw error;
  }
};


export const fetchUserDataById = async (id:string,regId:string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageUserProfile?reg_gid=`+regId+`&userlogin_gid=`+id);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAquariResources = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageAqariResources`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postChangePassword = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ChangePassword/ChangeUserPassword`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const LoginAction = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Login`, userData);
    return response;
  } catch (error) {
    // Handle the error here if needed
    throw error;
  }
};
export const RegistrationAction = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/RegisterUser`, userData);
    return response;
  } catch (error) {
    // Handle the error here if needed
    throw error;
  }
};

export const RegistrationByAdminAction = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/RegisterUser`, userData);
    return response;
  } catch (error) {
    // Handle the error here if needed
    throw error;
  }
};
// export const SubscriptionApproval = async (userData: any, token:string): Promise<AxiosResponse> => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/ManageSubscriptionsType/ApprovedSubscription`, userData);
//     return response;
//   } catch (error) {
//     // Handle the error here if needed
//     throw error;
//   }
// };

export const SubscriptionApproval = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/ManageSubscriptionsType/ApprovedSubscription`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

// export const SubscriptionRenew = async (userData: any,token:any): Promise<AxiosResponse> => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/ManageUser/Upgradesubscription`, userData);
//     return response;
//   } catch (error) {
//     // Handle the error here if needed
//     throw error;
//   }
// };

export const SubscriptionRenew = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageUser/Upgradesubscription`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};


export const getMasterData = async ( tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
     // `${API_BASE_URL}/ManageUserProfile/GetUserDetails?reg_gid=${regId}&userlogin_gid=${id}`,
     `${API_BASE_URL}/GetSubscriptionMaster/GetSubscriptionMasterTables`,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
   
    throw error;
  }
};


export const GetPermittedMappingSubscriptionAqariResourcesList = async ( ): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/MappingSubscriptionAqariResources/GetPermittedMappingSubscriptionAqariResourcesList`,
      
    );
    return response;
  } catch (error) {
   
    throw error;
  }
};


// export const fetchUserList = async (token:string): Promise<AxiosResponse> => {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/ManageUser/GetUserList`);
//     return response;
//   } catch (error) {
//     // Handle the error here if needed
//     throw error;
//   }
// };

export const fetchUserList = async ( tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageUser/GetUserList`,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
   
    throw error;
  }
};

export const ResetPasswordAction = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/PasswordRecovery/ForgotPassword`, userData);
    return response;
  } catch (error) {
    // Handle the error here if needed
    throw error;
  }
};

export const NewSubscription = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageSubscriptionRequest`, userData);
    return response;
  } catch (error) {
    // Handle the error here if needed
    throw error;
  }
};

export const SubscriptionActivation = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageUserProfile/SubscriptionActivation`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const SessionLogout = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageUserProfile/UserSignout`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const getMasterwithouttoken = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/GetSubscriptionMaster/GetSubscriptionMasterTables`,

    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const SubscriptionUpgradeCancle = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageUser/Cancelupgradesubscription`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const UploadNews = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageNews/SaveNews`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const PaymentReportApi = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/PaymentReport/PaymentReport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};

export const getPaymentStatus = async ( tokentobesent: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
     `${API_BASE_URL}/GetSubscriptionMaster/GetPaymentstatus`,
      {
        headers: {
          authorized: tokentobesent
        }
      }
    );
    return response;
  } catch (error) {
   
    throw error;
  }
};

export const SaveUserAnalytics = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/UserAnalytics/SaveUserAnalytics`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const GetAqariMapviewerAnalytics = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/UserAnalytics/GetAnalytics`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const GetLoginAnalytics = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/UserAnalytics/GetLoginAnalytics`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const getresetpasswordlink = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/PasswordRecovery/ResetPassword`,
      userData,
      
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const SubmitPredefinedReport = async (userData: any, tokenvalue: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/PaymentReport/PaymentReport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
};


export const ResetUserPassword = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ResetPassword/ResetUserPassword`,
      userData,
      
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const getmasterReportType = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getmReportType`
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const getpredefinedreport = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getPredefinedreports`,
   
    );
    return response;
  } catch (error) {
    // Handle error appropriately, e.g., log it or throw a custom error
    throw error;
  }
  
};

export const getcomponentreport = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getComponentreports`,
   
    );
    return response;
  } catch (error) {

    throw error;
  }
};

export const RegisterWithoutSubscription = async (userData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageCustomReports/registeruserwithoutsubscription`,
      userData,
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};
export const Approvereport = async (tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getreportapprovallist`,
    {
      headers: {
        authorized: tokenvalue
      }
    }
      
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};


export const Approvereportbyid = async (userData: any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageCustomReports/approvestatusofreport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
}

export const AddPredefinedreport = async (userData: any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageCustomReports/managepredefinedreport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
}

export const AddComponentreport = async (userData: any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageCustomReports/managecomponentreport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
}

export const getResoureGroup = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getrsourcegroup`
      
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};

export const getRequestedReportById = async (usergid :any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/GetAllUserReportRequest/`+usergid,
    {
      headers: {
        authorized: tokenvalue
      }
    }
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};

export const getApprovedReportById = async (usergid :any,gid:any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getuserreportlistbyid/`+usergid  +"/"+gid, 
    {
      headers: {
        authorized: tokenvalue
      }
    }
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};


export const getDuration = async ( ): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ManageCustomReports/getduration`
    );
    return response;
  } catch (error) {
    
    throw error;
  }
  
};

export const Newreportrequest = async (userData: any,tokenvalue:any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ManageCustomReports/newrequestforreport`,
      userData,
      {
        headers: {
          authorized: tokenvalue
        }
      }
      
    );
    return response;
  } catch (error) {
    
    throw error;
  }
}




























